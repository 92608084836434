import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './navbar.module.css'
import logo3 from '../../../assets/logo3.png'
import shadowVertLeft from '../../../assets/shadow_vert_left.png'
import { BoxArrowLeft } from 'react-bootstrap-icons';
import '../../../App.css';
import { userLogOut } from '../../../firebase/helpers';

export default function NavbarApp(props) {
  const history = useHistory()
  const { title } = props

  function navigate() {
    history.push('/sign-in')
  }

  function logOut () {
    userLogOut()
    navigate()
  }
  return (
    <div className={styles.navbarWrapper}>
      <div className={styles.navbarTitle}>
            <div onClick={() => history.push('/home')}><img src={logo3} height={'50px'} title={'המפתח לזיהוי בעיה'} alt={'המפתח לזיהוי בעיה'}/></div>
            <div className={styles.shadowVertLeft}><img src={shadowVertLeft} height={'35px'} alt={''}/></div>
            <div className={`textGreen ${styles.dynamicTitle} xxl-text`}>{title}</div>
      </div>
      <div className={styles.menuBars}>
        <div className={'textGreen'} onClick={logOut} title={'יציאה מהמערכת'} style={{cursor: 'pointer'}}><BoxArrowLeft/></div>
      </div>
    </div>
  )
}