import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import NativeListener from 'react-native-listener';
import styles from './index.module.css';
import '../../../App.css';
import { ChatSquareText } from 'react-bootstrap-icons';

export default function SummaryItem(props) {
    const { gridItemData, itemKey, textColorClass } = props;
    const classifyItemsContainers = useSelector(state => state.inputs.page_4.containers);
    const [dropDownVisible, setDropDownVisibilty] = useState(false)

    return (
        <NativeListener stopClick>
            <Dropdown key={itemKey} className={styles.dropDownWrapper} >
                <Dropdown.Toggle 
                    className={`${gridItemData.header.size} ${styles.dropDownBtn} ${textColorClass} summary_item_p font-weight-bold p-0 m-0`} 
                    size={'sm'} 
                    variant="transparent" 
                    onMouseEnter={() => setDropDownVisibilty(true)} 
                    onMouseLeave={() => setDropDownVisibilty(false)}
                    onClick={() => {setDropDownVisibilty(true)}}>
                    {gridItemData.header.text}
                        <br/> 
                    <span className={'xs-text d-block font-weight-normal textGray'}>{classifyItemsContainers.find(c => c.slug === gridItemData.slug).items.length} פריטי מידע <span className={'textGreen mr-1'}> <ChatSquareText size={14} /></span></span>
                </Dropdown.Toggle>
                <Dropdown.Menu show={dropDownVisible} className={`${styles.dropDownMenuWrapper} textGray thick-custom-scrollbar`}>
                    <Dropdown.Item className={`textWhite font-weight-bold bgBlue sticky-top rounded-top`}>פריטים שסווגו כ{gridItemData.slug}</Dropdown.Item>
                    <Dropdown.Divider />
                    <div className={`${styles.dropDownMenu} custom-scrollbar`}>
                        {classifyItemsContainers.find(c => c.slug === gridItemData.slug).items.length ?
                            classifyItemsContainers.find(c => c.slug === gridItemData.slug).items.map((item) => {
                                return (<Dropdown.Item key={item.infoItemId} className={`textGray sm-text`} title={item.descriptText}>
                                        {item.descriptText && item.descriptText.length > 100 ? item.descriptText.substr(0,100) + '...' : item.descriptText}
                                        </Dropdown.Item>);
                            }) : <Dropdown.Item className={`sm-text text-center`} disabled>{`לא סווגו פריטים ל${gridItemData.slug}`}</Dropdown.Item>
                        }
                    </div>
                </Dropdown.Menu> 
            </Dropdown>
        </NativeListener>
    )
}