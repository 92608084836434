export const ACTIONS = {
    UPDATE_PAGE_SLUG: "UPDATE_PAGE_SLUG",
    UPDATE_PAGE_1: "UPDATE_PAGE_1",
    UPDATE_PAGE_2: "UPDATE_PAGE_2",
    UPDATE_PAGE_3: "UPDATE_PAGE_3",
    UPDATE_CHAIN: "UPDATE_CHAIN",
    SET_REQUESTED_ITEMS: "SET_REQUESTED_ITEMS",
    SET_DRAGGED_ITEMS: "SET_DRAGGED_ITEMS",
    SET_DOCUMENT_STATE: "SET_DOCUMENT_STATE",
    ADD_INFO_ITEM: "ADD_INFO_ITEM",
    SET_ACTIVE_INFO_ITEM: "SET_ACTIVE_INFO_ITEM",
    REMOVE_INFO_ITEM: "REMOVE_INFO_ITEM",
    REMOVE_CHAIN: "REMOVE_CHAIN",
    DUPLICATE_CHAIN: "DUPLICATE_CHAIN",
    ADD_CHAIN: "ADD_CHAIN",
    ADD_CHAIN_ITEM: "ADD_CHAIN_ITEM",
    UN_CLASSIFY_CHAIN: "UN_CLASSIFY_CHAIN",
    FIND_MISSING_INFORMATION_ITEMS: "FIND_MISSING_INFORMATION_ITEMS",
    GET_INFO_ITEMS_IN_PERCENTAGE: "GET_INFO_ITEMS_IN_PERCENTAGE",
    CHECK_MAIN_CLASSIFICATIONS: "CHECK_MAIN_CLASSIFICATIONS",
    INITIAL_SUMMARY_STATE: "INITIAL_SUMMARY_STATE",
    UPDATE_SUMMARY_FIELDS: "UPDATE_SUMMARY_FIELDS",
}

