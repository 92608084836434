import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import NavbarApp from "../../../ui-components/nav-bar";
import "../../../../App.css";
import background_2 from '../../../../assets/background_2.png'
import { ChatSquareText, ChevronLeft, ChevronRight, Info, InfoSquare, ListUl, Printer } from "react-bootstrap-icons";
import MainLogo from "../../../ui-components/mainLogo";
import QuestFrame from "../../../ui-components/quest-frame";
import CircleButton from '../../../ui-components/circle-button'; 
import { removeCookie } from '../../../../firebase/helpers';
import fire from 'firebase/app';
import 'firebase/auth'
import { appConfiguration } from '../../../../store/configuration';
import ReactTooltip from 'react-tooltip';

export default function IntroPage() {
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                removeCookie(["currentDocId"])
                localStorage.removeItem("_binddata");
            } else {
                history.push("/sign-in");
            }
        });
        window.onresize = () => {
            setWindowWidth(window.innerWidth)
          }
        window.onbeforeunload = null;
        window.onload = null;
    }, []);
    return (
        <div className={"main-page-wrapper"}>
            <NavbarApp title={appConfiguration.questPagesConfig.appPages.unOrderedPages.find((page) => page.slug === "intro_page").title} />

            <div className={"inner-wrapper"}>
                <div className={'inner-grid'}>
                    <div className={'right-side-strip'}>
                        <div className={'flex-grow-1'}></div>
                        <div className={"attachToBottom"}>
                            <MainLogo />
                        </div>
                    </div>
                    <div className={'main-content-wrapper'}>
                        <div className={'main-content'}>
                                <div className={`row h-100 textGray ${windowWidth < 1200 ? 'sm-text' : 'md-text'} w-100`}>
                                    <div className={'col d-flex flex-column m-0 p-0 w-100'}>
                                        <QuestFrame
                                            frameType={"question"}
                                            additionalStyles={{dispay: 'flex', height: windowWidth < 1200 ? '100%' : '40%', padding: '10px 25px 10px 10px' }}>
                                            <div className={'d-flex flex-column h-100 justify-content-around'}>
                                                <p className={'m-0 ml-5 w-100'}>
                                                    זיהוי בעיה מהווה חלק מרכזי וקריטי בתהליך גיבוש רגולציה.<br/>
                                                    הכלי לזיהוי בעיה מהווה בסיס לדיון מקצועי,<br/>
                                                    פיתחנו עבורך את הכלי על מנת לעורר חשיבה מושכלת, שיטתית ומודעת להטיות.
                                                </p>
                                                {windowWidth < 1200 && <div>
                                                    <ul className={'m-0 p-0'}>
                                                        <li> הכלי מנוסח בלשון נקבה מטעמי נוחות בלבד.</li>
                                                        <li>בכל שלב ניתן לנווט בין המסכים ולשנות נתונים.</li>
                                                        <li>נתוני המסמך נשמרים באופן אוטומטי, ניתן לחזור בכל שלב ולערוך את המסמכים.</li>
                                                        <li className={'textRed'}> לתשומת לבך, המידע המוזן למערכת מאוחסן בענן. לכן, כדי להימנע מזליגת מידע סודי ו/או רגיש -  אנא הימנעי מהזנתו במערכת. </li>
                                                    </ul>
                                                </div>}

                                            </div>
                                        </QuestFrame>
                                        {windowWidth > 1200 && <QuestFrame
                                            frameType={"unorer"}
                                            iconElem={<ListUl size={26}/>}
                                            additionalStyles={{ marginTop: "10px", flex: '1', display: 'flex', alignItems: 'center', padding: '5px 10px' }}>
                                            <ul>
                                                <li> הכלי מנוסח בלשון נקבה מטעמי נוחות בלבד.</li>
                                                <li>בכל שלב ניתן לנווט בין המסכים ולשנות נתונים.</li>
                                                <li>נתוני המסמך נשמרים באופן אוטומטי, ניתן לחזור בכל שלב ולערוך את המסמכים.</li>
                                                <li className={'textRed'}> לתשומת לבך, המידע המוזן למערכת מאוחסן בענן. לכן, כדי להימנע מזליגת מידע סודי ו/או רגיש -  אנא הימנעי מהזנתו במערכת. </li>
                                            </ul>
                                        </QuestFrame>}
                                    </div>
                                    <div className={'col d-flex w-100'}>
                                        <ReactTooltip
                                        id={'example'}
                                        place={'top'}
                                        effect={'float'}
                                        className={'boxShadow font-weight-bold textBlue'}
                                        backgroundColor={'white'}
                                        arrowColor={'white'}
                                        multiline={true}/>
                                        <QuestFrame frameType={"unorder"} additionalStyles={{flex: '1', padding: '5px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <p className={' font-weight-bold textGreen text-center md-plus-text'}>הסבר על אייקונים</p>
                                            <div className={"d-flex mb-3 mr-3 w-100"}>
                                                <CircleButton
                                                    action={() => null}
                                                    buttonColor={'#82BB2D'}
                                                    iconComponent={<Info color={'white'} size={26}/>}
                                                    containerAdditionalStyle={{marginLeft: '5px', display: 'inline-block'}} 
                                                    iconCircleAdditionalStyle={{width: '25px', height: '25px'}} />
                                                    <span className={'w-100'}> לינק למדריך לצורך הרחבה, בלחיצה על <span className={'generalBlueLink textBlue'}>לינק</span> ייפתח חלון מידע. </span>
                                            </div>
                                            <div className={"d-flex mb-3 mr-3"}>
                                                <CircleButton
                                                    action={() => null}
                                                    buttonColor={'#82BB2D'}
                                                    iconComponent={<Printer color={'white'} size={18}/>}
                                                    containerAdditionalStyle={{marginLeft: '5px', display: 'inline-block'}}
                                                    iconCircleAdditionalStyle={{width: '25px', height: '25px'}} />
                                                    <span >ניתן להדפיס את המסך הזה לנוחיות המשתמש <span className={'sm-text'}>{"(לא כל מסך ניתן להדפסה)"}</span></span>
                                            </div>
                                            <div className={"d-flex mb-3 mr-3"}>
                                                <ChatSquareText className={'textGreen ml-3'} size={26} data-for={'example'} data-tip={'חלונית הסבר'} />
                                                <span>עמוד על אייקון זה להצגת חלונית הסבר.</span>
                                            </div>
                                            <div className={"d-flex "}>
                                                <ChevronRight className={"browseArrows textGreen"} style={{fontSize: '1.8rem'}}/>
                                                <ChevronLeft className={"browseArrows textGreen"} style={{fontSize: '1.8rem'}}/>
                                                <span>לחצני ניווט בין דפי המסמך, נמצא בפינה השמאלית התחתונה.</span>
                                            </div>
                                        </QuestFrame>
                                    </div>
                                </div>
                            <div className={'h40'} style={{ height: 'auto', marginTop: 'auto'}}>
                                <img src={background_2} className={'responsive-img'} alt={''}/>
                            </div>
                        </div>
                    </div>
                    <div className={'left-side-strip'}>
                        <div className={'flex-grow-1 height'}></div>
                        <div className={"navButtonsWrapper textGreen"}>
                            <ChevronRight className={"browseArrows-inactive"}/>
                            <ChevronLeft className={"browseArrows"} onClick={() => history.push("./home")}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'downer-line'}></div>
        </div>
    )
}