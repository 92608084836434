import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import PillButton from '../pillButton';
import '../../../App.css'

export default function InputModal(props) {
    const { children, modalTitle = 'הכנס/י או בחר/י ערך', onAbort, show, onHide, handleSubmit } = props; 
    
    return (
        <Modal
        onHide={onHide}
        show={show}
        size="lg"
        animation={true}
        contentClassName={'modalBox'}
        centered
      >
        <div className={'modalHeader'}>
            <div className={'md-text flex-grow-1 title-tag bgBlue'}>
              {modalTitle}
            </div>
        </div>
        <div className={'modalBody'}> {children} </div>
        <div className={'modalFooter'}>
          <PillButton
            buttonColor={'#82bb2d'} 
            text={'שמירה'} 
            action={handleSubmit}
            containerAdditionalStyle={{ marginLeft: '10px'}} 
            additionalClasses={'sm-text'}
          />
          <PillButton
            buttonColor={'#e84e0e'} 
            text={'ביטול'} 
            action={onAbort}
            additionalClasses={'sm-text'}
          />
        </div>
      </Modal>
    )
}