import React from 'react';
import styles from  './loader.module.css';

export default function Loader() {

    return (
        <div className={`${styles.loaderWrapper}`}>
            <div className={`${styles.titleItems}`}>
                <div className={`${styles.title} ${styles.titlesAnimationOpacity}`}></div>
                <div className={`${styles.title} ${styles.titlesAnimationOpacity}`}></div>
                <div className={`${styles.title} ${styles.titlesAnimationOpacity}`}></div>
                <div className={`${styles.title} ${styles.titlesAnimationOpacity}`}></div>
            </div>
            <div className={`${styles.shortTitleItems}`}>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            <div className={`${styles.shortTitle} ${styles.titlesAnimationOpacity}`}></div>
            </div>
        </div>
    )
}
