import React, { useEffect, useState } from 'react';
import styles from './questFrame.module.css'
import '../../../App.css'

export default function QuestFrame(props) {
    const { children, frameType, additionalStyles, additionalClasses, orderNum, iconElem = false } = props;
    const [frameClass, setFrameClass] = useState(null)

    function applyFrameType() {
        switch(frameType) {
            case 'order': setFrameClass(styles.questFrameWrapper); break;
            case 'unorder': setFrameClass(styles.questFrameWrapper_unordered); break;
            case 'info-item': setFrameClass(styles.questFrameWrapper_info_item); break;
            case 'question': setFrameClass(styles.questFrameWrapper_question); break;
            default: break;
        }
    }
    useEffect(() => { applyFrameType() }, [])
    return (
        <div style={additionalStyles} className={`fade-in quest-frame ${[frameClass]} ${additionalClasses} ${styles.generalQuestFrame}`} value={orderNum}>
            {iconElem && <div className={`${styles.iconWrapper}`}>{iconElem}</div>}
            {children}
        </div>
    )
}