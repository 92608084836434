import React from 'react';
import { ChevronBarLeft, ChevronBarRight, ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import severity_icon from '../assets/icons/severity_icon.png';
import activity_scope_icon from '../assets/icons/activity_scope_icon.png';
import vulnerability_amount_icon from '../assets/icons/vulnerability_amount_icon.png';

export const appConfiguration = {
    userAgent: checkBrowser(),
    appStyles: {
        mainColor: '#4a54a0',
        secondaryColor: '#82bb2d',
        redColor: '#e84e0e',
        grayColor: '#656565',

    },
    questPagesConfig: {
        appPages: {
          orderedPages: [
            { title: 'חלק ראשון - חימום', slug: 'page_1'},
            { title: 'חלק ראשון - חימום', slug: 'page_2'},
            { title: 'חלק שני - #התחלנו!', slug: 'page_3'},
            { title: 'חלק שני - #התחלנו!', slug: 'page_4'},
            { title: 'חלק שני - #התחלנו!', slug: 'page_5'},
            { title: 'חלק שלישי - כמעט סיימנו...', slug: 'page_6'},
            { title: 'חלק רביעי - אז מה הבנו עד כה?', slug: 'summary_page'},
          ],
          unOrderedPages: [
            { title: 'ברוכים הבאים!', slug: 'home_page'},
            { title: 'ברוכים הבאים!', slug: 'intro_page'},
          ]
        },
    },
    toastMessages: {
        factAdded: 'עודכן תיעוד לפריט מידע מספר ',
        factAddeddFromCloud: 'עודכן תיעוד לפריט המידע',
        factRemove: 'התיעוד הוסר מפריט המידע',
        chainClass_severity_item_modified: 'פריט חומרה עודכן',
        chainClass_frequency_amount_modified: 'פריט כמות הפגיעות עודכן',
        chainClass_frequency_scope_modified: 'פריט היקף הפעילות עודכן',
        chainClassAbort: 'השינויים בוטלו',
    },
    page_4PopupsContent: [
        { slug: 'חומרה', content: 'ביטוי לרמת הנזק בעת התממשות הסיכון. לרוב נסמן פגיעות גוף ונפש כחמורות יותר מפגיעות ברכוש, ונזק בלתי הפיך כחמור יותר מנזק הפיך. ' },
        // { slug: 'הסתברות', content: 'הסיכוי להסתברות הסיכון. חישוב מקרי התממשות הסיכון ביחס לכלל הפעילות הקיימת בתחום. למשל, מספר השריפות  בבניינים רבי קומות חלקי מספר הבניינים רבי הקומות שישנם. ' },
        // { slug: 'כמות הפגיעות', content: 'כמות הפגיעות הסבר.' },
        // { slug: 'היקף הפעילות', content: 'היקף הפעילות הסבר.' },
    ],
    buttonsInPage_2: [
        'השפעות חברתיות','השפעות כלכליות','השפעות סביבתיות','יוקר המחיה','זכויות אדם',
        'עסקים קטנים','בריאות','תעסוקה','צמצום פערים','תחרות','פיתוח הפריפריה','ביטחון','אחר'
    ],
    infoItemsSearchTool: [
        { icon: <ChevronBarRight size={45}/>, title: 'פריט ראשון', textElem: <span>פריט ראשון</span>, browseOrder: 'START'},
        { icon: <ChevronRight size={45}/>, title: 'פריט קודם', textElem: <span>פריט קודם</span>, browseOrder: 'PREV'},
        { icon: <ChevronLeft size={45}/>, title: 'פריט הבא', textElem: <span>פריט הבא</span>, browseOrder: 'NEXT'},
        { icon:  <ChevronBarLeft size={45}/>, title: 'פריט האחרון', textElem: <span>פריט אחרון</span>, browseOrder: 'END'},
    ],
    summaryItems: [
        {
            slug: 'חומרה',
            flexWidth: '60%',
            itemAlignment: 'pr-5',
            icon: { source: severity_icon , alt: 'אייקון מטרה', width: '45vw', height: '45vh'},
            header: { text: 'חומרה', size: 'lg-text'},
        },
        {
            slug: 'כמות הפגיעות',
            flexWidth: '40%',
            itemAlignment: 'pl-5',
            icon: { source: vulnerability_amount_icon , alt: 'אייקון תיק רפואי', width: '35vw', height: '30vh'},
            header: { text: 'כמות הפגיעות', size: 'md-text'},
        },
        {
            slug: 'היקף הפעילות',
            flexWidth: '40%',
            itemAlignment: 'pr-5',
            icon: { source: activity_scope_icon , alt: 'אייקון סטופר', width: '35vw', height: '45vh'},
            header: { text: 'היקף הפעילות', size: 'md-text'},
        },
    ]
}

function checkBrowser() {
    // Get the user-agent string
    const userAgentString = navigator.userAgent;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    
    // Detect Internet Explorer
    let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;
    
    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
    if ((IExplorerAgent) && (firefoxAgent)) IExplorerAgent = false;
    
    // Detect Safari
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) safariAgent = false;
    
    // Detect Opera
    let operaAgent = userAgentString.indexOf("OP") > -1;
    // Discard Chrome since it also matches Opera     
    if ((chromeAgent) && (operaAgent)) chromeAgent = false;

    return { safariAgent : safariAgent, chromeAgent : chromeAgent, IExplorerAgent : IExplorerAgent, operaAgent : operaAgent, firefoxAgent : firefoxAgent }
}