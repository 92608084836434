import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { getFirebaseErrorMessage } from "../../../../firebase/commonUtils";
import styles from './index.module.css';
import '../../../../App.css';
import MainLogo from "../../../ui-components/mainLogo";
import logo2 from '../../../../assets/logo2.png';
import PillButton from "../../../ui-components/pillButton";
import fire from "../../../../firebase";

export default function ForgotPassword() {
  const initialLocalState = {userName: '', confirmMsg: false, fireErrors: ''}
  const [localState, setLocalState] = useState(initialLocalState)
  const history = useHistory()

  function handleSubmit(e) {
    e.preventDefault();
    fire.auth().sendPasswordResetEmail(localState.userName).then(() => {
      setLocalState({...localState, fireErrors: '', confirmMsg: true })
    }).catch((error) => {
      let errorCode = error.code;
      setLocalState({...localState, confirmMsg: false, fireErrors: getFirebaseErrorMessage(errorCode)})
    });
  }

  function updateInputValue(value, key) {
    setLocalState({
      ...localState, confirmMsg: false, fireErrors: '', [key]: value
    });
  }
  return (
    <div className={styles.forgotMain}>
      <div className={styles.pageWrapper}>
        <div className={styles.uppearStrip}>
          <div>
            <MainLogo />
          </div>
          <div className={'mr-auto'}>
            <img src={logo2} height={"80px"} alt={'מדיניות רגולציה'}/>
          </div>
        </div>
        <div className={styles.bottomStrip}>
          <div className={styles.pageHeader}>
            <h1>
                <span className={`textBlue font-weight-bold`}> המפתח </span> 
                <span className={`textGreen`}>לזיהוי בעיה</span>
            </h1>
          </div>
          <div className={'flex-grow-1 d-flex'}>
            <div className={`${styles.boxWrapper}`}>
              <div className={styles.forgotHeader}>
                <h3>שחזור <span className={`textBlue font-weight-bold`}>סיסמא</span></h3>
                <div className={styles.lineBottom}></div>
              </div>
              <form className={styles.forgotForm}>
                  
                  <div className="form-group flex-grow-1 ">
                    <label className={`${styles.formLabel} textBold`}>דואר אלקטרוני</label>
                    <input type="email" className={`${styles.formInput}`}
                      onChange={evt => updateInputValue(evt.target.value, 'userName')}
                      placeholder="דואר אלקטרוני" />
                  </div>
                  <div className={styles.forgotFooter}>
                    <p className={`${styles.errorsMsg} textBlue`}>{localState.fireErrors}</p>
                    { localState.confirmMsg && <div className={styles.confirmMsg}>
                      <p className={'md-text p-0 m-0'}>נשלח לדואר אלקטרוני {localState.userName}</p>
                      <p className={'md-text p-0 m-0 generalBlueLink textBlue'} onClick={handleSubmit}>שלח שוב</p>
                    </div>}
                    <PillButton
                      action={handleSubmit}
                      buttonColor={'#82bb2d'}
                      text={'שחזור סיסמא'}
                      containerAdditionalStyle={{width: 'fit-content', height: '30px', margin: '0 auto',padding: '0% 10%'}}
                    />
                      <div className={'text-center mt-3'}><span className={'generalLink'} onClick={() => history.push('./sign-up')}>הרשמה</span> | <span className={`textGreen generalLink`} onClick={() => history.push('./sign-in')}> התחברות </span></div>
                    </div>
              </form>
            </div>
          </div>
          <div className={'downer-line mt-auto'}></div>
        </div>
      </div>
    </div>
  )
}