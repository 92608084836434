import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DescripContainer from "../../../../ui-components/descript-container";
import DescriptsCloud from "../../../../ui-components/descripts-cloud";
import { useHistory } from "react-router-dom";
import NavbarApp from "../../../../ui-components/nav-bar";
import { addInfoItem, setDraggedItems, setRequestedItems } from "../../../../../store/inputs-reducer/inputs.actions";
import { useSelector, useDispatch } from "react-redux";
import "../../../../../App.css";
import vector_4 from "../../../../../assets/Vector_4.png";
import QuestProgress from "../../../../ui-components/quest-progress";
import MainLogo from "../../../../ui-components/mainLogo";
import { ChevronLeft, ChevronRight, X } from "react-bootstrap-icons";
import QuestFrame from "../../../../ui-components/quest-frame";
import { getCookie } from "../../../../../firebase/helpers";
import { appConfiguration } from "../../../../../store/configuration";
import toast,{ Toaster } from 'react-hot-toast';

export default function QuestPage4() {
  const stateInputs = useSelector(state => state.inputs)
  const dispatch = useDispatch()
  const history = useHistory();
  
  function setRequestedItemsAction() {
    const payload = {
      classify: "תיאור הנזק",
      page: "page_4",
      mainArr: "containers",
      innerArr: "items",
    };
    dispatch(setRequestedItems(payload));
  }
  function setDraggedItemsAction(data, slug) {
    const payload = {
      draggedData: data,
      page: "page_4",
      mainArr: "containers",
      innerArr: "items",
      chainId: slug,
      droppedIndex: null,
    };
    dispatch(setDraggedItems(payload));
  }
  function addInfoItemAction(infoItemNewData) {
    const newInfoItemId = Date.now() + (Math.random() * 100000).toFixed();
    infoItemNewData.radioGroup_2 = 'תיאור הנזק'
    dispatch( addInfoItem({ infoItemId: newInfoItemId, page: 'page_4', addedFromCloud: true, infoItemData: infoItemNewData }) );
  }

  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    setRequestedItemsAction();
    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
    return () => {
      toast.dismiss()
    }
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
        localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
  }, [stateInputs]);
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_4").title} />
      <div style={{minWidth: 'fit-content', position: 'fixed', zIndex: '9999999', bottom: '50px', left: '50%', transform: 'translateX(-50%'}}><Toaster position="bottom-center" reverseOrder={false} toastOptions={{duration: 5000, style: { minWidth: '350px'}}}/></div>
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_4} className={'vector_4'} alt={''}/>
            </div>
            <div className={"attachToBottom"}>
              <MainLogo />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={'main-content'}>
              <QuestFrame orderNum={5} frameType={"order"} additionalStyles={{flexGrow: '1'}}>
                <div className={'d-flex flex-column h-100'}>
                  <div className={"font-weight-bold textBlue p-0 mb-3 md-text"}>
                    בצד שמאל מופיעים <span className={'textGreen md-plus-text'}> תיאורי הנזק </span> שהקלדת במסך פריטי המידע <span className={'xs-text text-muted'}>(מסך קודם)</span>.
                    <br />
                    <div className={"d-flex align-items-center text-muted font-weight-bold sm-text"}>
                      <p>
                        גררי כל תיאור למקומו המתאים על מנת לתאר את הפגיעה ברמת הדיוק הגבוהה ביותר.
                        הצביעי בעזרת סמן העכבר על כל מושג להסבר, ניתן לקרוא עוד על תיאור הפגיעה 
                       <span className={'generalBlueLink textBlue font-weight-bold'} onClick={() => null} size={'sm'} variant={'light'}> במדריך. </span>
                      </p>
                    </div>
                  </div>
                  <Row className={"d-flex flex-grow-1"}>
                    <Col className={"d-flex flex-wap"} xl={6} lg={6} md={6} sm={4} >
                      <div className={'flex-grow-1 d-flex flex-column font-weight-bold xl-text textBlue py-3'}>
                        {stateInputs.page_4.containers.map((container) => container.slug === 'חומרה' && <DescripContainer key={container.name} action={setDraggedItemsAction} {...container}/> )}
                      </div>
                      <div className={'d-flex justify-content-center textGreen'} style={{fontSize: '4vw'}}><X/></div>
                      <div className={'flex-grow-1 d-flex flex-column py-3'}>
                        <p className={'d-flex align-items-center justify-content-center textBlue font-weight-bold xl-text  m-0'}>הסתברות</p>
                        <div className={'flex-grow-1 d-flex flex-column align-items-cente justify-content-cente text-muted'}>
                          <div className={'flex-grow-1 d-flex flex-column h-100 md-plus-text'}>
                            {stateInputs.page_4.containers.map((container) => container.slug === 'כמות הפגיעות' && <DescripContainer key={container.name} action={setDraggedItemsAction} {...container}/> )}
                          </div>
                          <div className={'d-flex align-items-center justify-content-center bgGreen my-3'} style={{width: '100%', height: '9.5px', borderRadius: '25px'}}></div>
                          <div className={'flex-grow-1 d-flex flex-column h-100 md-plus-text'}>
                            {stateInputs.page_4.containers.map((container) => container.slug === 'היקף הפעילות' && <DescripContainer key={container.name} action={setDraggedItemsAction} {...container}/> )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className={"d-flex flex-column h-75 align-self-center"} xl={6} lg={6} md={6} sm={8}>
                      <DescriptsCloud requestedInfoItems={stateInputs.page_4.cloud} action={setDraggedItemsAction} addInfoItemAction={addInfoItemAction} cloudClass={'תיאור הנזק'} innerColumns={4} shortCloud={false}/>
                    </Col>
                  </Row>
                </div>
              </QuestFrame>
              <QuestFrame frameType={"question"} additionalStyles={{marginTop: '10px'}}>
                <p className={"font-weight-bold textBlue p-0 m-0 md-text"}>חשבי :</p>
                <small className={"text-muted font-weight-bold sm-text"}>בעקבות תיאור הנזק, האם וכמה מהזמן שלך נכון להשקיע בעיסוק בבעיה ?</small>
              </QuestFrame>
            </div>
            <div className={'bottom-content'}></div>
          </div>
          <div className={'left-side-strip'}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={3} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./page-3")}/>
              <ChevronLeft className={"browseArrows"} onClick={() => history.push("./page-5")}/>
            </div>
          </div>
        </div>
      </div>
      <div className={'downer-line'}></div>
    </div>
  );
}
