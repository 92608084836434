import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import styles from './checkbox-button.module.css'

export default function CheckBoxButton(props) {
    const { action , isChecked, name} = props
    return (
        <label className={`btn ${styles.checkboxBtn} ${isChecked && 'textBlue borderBlue2'} md-text`} title={name}>
            <FormControl type={'checkbox'} name={name} onChange={action} defaultChecked={isChecked}/>
            {name}
        </label>
    )
}