import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import styles from './modal.module.css';
import '../../../App.css';
import PillButton from '../pillButton';

export default function InfoModal(props) {
    const {children, title, onHide, show} = props;

    return (
        <Modal
        onHide={onHide}
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        animation={true}
        contentClassName={styles.modalBox}
        centered
        scrollable
      >
        <div className={styles.modalHeader}>
          <p className={'lg-text textBlue'}>{title}</p>
        </div>
        <div className={`${styles.modalBody} custom-scrollbar contentFade`}>
              { children }
          </div>
        <div className={styles.modalFooter}>
          <PillButton
              buttonColor={'#82bb2d'} 
              text={'סגירה'} 
              containerAdditionalStyle={{ margin: '0 auto', width: 'fit-content'}} 
              action={onHide}/>
        </div>
      </Modal>
    )
}