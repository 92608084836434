import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useHistory } from "react-router-dom";
import { addChain, addInfoItem, setDraggedItems, setRequestedItems } from "../../../../../store/inputs-reducer/inputs.actions";
import Chain from "../../../../ui-components/chain";
import DescriptsCloud from "../../../../ui-components/descripts-cloud";
import InfoModal from "../../../../ui-components/modal";
import NavbarApp from "../../../../ui-components/nav-bar";
import { useSelector, useDispatch } from "react-redux";
import vector_6 from "../../../../../assets/Vector_6.png";
import QuestFrame from "../../../../ui-components/quest-frame";
import { ChevronLeft, ChevronRight, Plus } from "react-bootstrap-icons";
import QuestProgress from "../../../../ui-components/quest-progress";
import MainLogo from "../../../../ui-components/mainLogo";
import CircleButton from "../../../../ui-components/circle-button";
import { getCookie } from "../../../../../firebase/helpers";
import { findEmptyChain } from "../helpers";
import { appConfiguration } from "../../../../../store/configuration";
import toast , { Toaster } from "react-hot-toast";
import QuestionBank from "../../../visual-containers/questions-bank";

export default function QuestPage6() {
  const stateInputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const history = useHistory();
  const chainsEndRef = useRef(); 

  function setRequestedItemsAction() {
    const payload = {
      classify: "סיבה לבעיה",
      page: "page_6",
      mainArr: "chains",
      innerArr: "chainItems",
    };
    dispatch(setRequestedItems(payload));
  }

  function setDraggedItemsAction(data, chainId, itemIndex) {
    const payload = {
      draggedData: data,
      page: "page_6",
      mainArr: "chains",
      innerArr: "chainItems",
      chainId: chainId,
      droppedIndex: itemIndex,
    };
    dispatch(setDraggedItems(payload));
  }

  function addInfoItemAction(infoItemNewData) {
    const newInfoItemId = Date.now() + (Math.random() * 100000).toFixed();
    infoItemNewData.radioGroup_2 = "סיבה לבעיה";
    dispatch(
      addInfoItem({
        infoItemId: newInfoItemId,
        page: "page_6",
        addedFromCloud: true,
        infoItemData: infoItemNewData,
      })
    );
  }

  function checkEmptyChains() {
    const emptChainArr = findEmptyChain(stateInputs, 'page_6')
    if(emptChainArr[0]) {
      const delayOverlay = stateInputs.page_6.chains.length < 10 ? stateInputs.page_6.chains.length * 200 : stateInputs.page_6.chains.length * 50;
      scrollTo(false, emptChainArr[1])
      setTimeout(() => setShowOverlay([true, emptChainArr[1]]), delayOverlay)
      setTimeout(() => setShowOverlay(false), 5500)
    } else {
      addChainAction()
    };
  }
  function addChainAction() {
      dispatch(addChain("page_6"));
      scrollTo(true)
  }
  function scrollTo(ref, eleID) {
    setTimeout(() => {
      ref ? 
      chainsEndRef.current.scrollIntoView({ behavior: "smooth" }) :
      document.getElementById(eleID).scrollIntoView({ behavior: "smooth" });
    }, 100);
  }


  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    setRequestedItemsAction();
    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
    return () => {
      toast.dismiss()
    }
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
  }, [stateInputs]);
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_6").title}/>
      <div style={{width: 'fit-content', position: 'fixed', zIndex: '9999999', bottom: '50px', left: '50%', transform: 'translateX(-50%'}}><Toaster position="bottom-center" reverseOrder={false} toastOptions={{duration: 5000, style: { minWidth: '350px'}}}/></div>
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_6} className={'vector_6'} alt={""} />
            </div>
            <div className={"attachToBottom"}>
              <MainLogo height={"70px"} />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={'main-content'}>
              <InfoModal show={modalShow} onHide={() => setModalShow(false)} title={'בנק שאלות מנחות'}><QuestionBank/></InfoModal>
              <QuestFrame
                orderNum={7}
                frameType={"order"}
                additionalStyles={{ display: 'flex', flexDirection: 'column', flexGrow: '1', minHeight: '300px'}}>
                <p className={"font-weight-bold textBlue p-0 mb-2 md-text"}>
                  האם יש אפשרות להבין את השרשרת הסיבתית <span className={'textGreen md-plus-text'}>באופן אחר ?</span> 
                  <br />
                  <span className={"text-muted font-weight-bold attachedText sm-text"}>
                    תארי לפחות שרשרת סיבתית לפגיעה חדשה ואופציונלית בצורה לינארית
                    ככל הניתן {"(א' גורם ל -> ב' שגורם ל -> ג' וכו')"}.{"\n"}
                    עבור כל גורם בשרשרת סמני האם חסר לך מידע בנושא או אם זו עובדה או השערה.
                    {" "}
                    <span className={"generalBlueLink textBlue"} onClick={() => setModalShow(true)}> בנק שאלות מנחות ממתין כאן. </span>
                  </span>
                </p>
                <Row className={'h-100'}>
                  <Col className={"position-relative d-flex"} xl={7} lg={7} md={6} sm={6} >
                  <div className={"d-flex align-items-start custom-scrollbar"} style={{ position: 'absolute', overflowX: "auto", overflowY: "hidden" , width: '85%', height: '100%'}}>
                    {stateInputs.page_6.chains.map((chain, i) => {
                      return (
                          <Chain
                            key={chain.chainId}
                            domID={chain.chainId}
                            action={setDraggedItemsAction}
                            checkEmptyChains={checkEmptyChains}
                            index={i}
                            page={"page_6"}
                            scrollTo={scrollTo}
                            showOverlay={showOverlay}
                            {...chain}
                          />
                      );
                    })}
                    <div ref={chainsEndRef}></div>
                  </div>
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1}>
                    <CircleButton
                          action={checkEmptyChains}
                          buttonColor={'#82BB2D'}
                          textUnder={true}
                          text={'שרשרת חדשה'}
                          iconComponent={<Plus color={'white'} size={32}/>}
                          triggerBooleanState={false}
                          additionalClasses={'sm-text'}
                          containerAdditionalStyle={{margin: '0 auto'}}
                          textAdditionalStyle={{marginTop: '5px'}} />
                  </Col>
                  <Col className={''} xl={4} lg={4} md={5} sm={5}>
                      <DescriptsCloud
                        requestedInfoItems={stateInputs.page_6.cloud}
                        action={setDraggedItemsAction}
                        addInfoItemAction={addInfoItemAction}
                        cloudClass={'סיבה לבעיה'}
                        innerColumns={2}
                        shortCloud={true}
                      />
                  </Col>
                </Row>
              </QuestFrame>
              <QuestFrame
                frameType={"question"}
                additionalStyles={{ marginTop: "10px" }}>
                <p className={"font-weight-bold textBlue p-0 m-0 attachedText d-block md-text"}>חשבי :</p>
                <span className={"text-muted font-weight-bold sm-text"}>
                  איזה מידע יגרום לך לאמץ שרשרת סיבתית אחרת? האם מידע זה זמין?
                </span>
              </QuestFrame>
            </div>
            <div className={'bottom-content'}></div>
          </div>
          <div className={'left-side-strip'}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={5} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./page-5")}/>
              <ChevronLeft className={"browseArrows"} onClick={() => history.push("./summary-page")}/>
            </div>
          </div>
        </div>
      </div>
      <div className={"downer-line"}></div>
    </div>
  );
}