import { deleteDocsValue, getCookie, updateDocumentByPath } from "../../firebase/helpers";
import { ACTIONS } from "../actions.config";

export const initialUserInputsState = {
  page_1: {
    input_A: "",
    input_B: "",
  },
  page_2: {
    input_A: "",
    selectButtons: [],
  },
  page_3: {
    infoItemsArr: [{infoItemId: Date.now() + (Math.random() * 100000).toFixed(), radioGroup_1: 'השערה', infoItemIndex: 1}],
    activeInfoItem: null
  }
  ,
  page_4: {
    cloud: [],
    containers: [
      { items: [], slug: "חומרה", name: "חומרה" },
      { items: [], slug: "כמות הפגיעות", name: "כמות הפגיעות" },
      { items: [], slug: "היקף הפעילות", name: "היקף הפעילות" },
    ],
  },
  page_5: {
    cloud: [],
    chains: [
      {
        chainId: `${Date.now()}${Math.floor(Math.random() * 999999)}`,
        chainName: "",
        chainItems: [" ", " ", " "],
      },
    ],
  },
  page_6: {
    cloud: [],
    chains: [
      {
        chainId: `${Date.now()}${Math.floor(Math.random() * 999999)}`,
        chainName: "",
        chainItems: [" ", " ", " "],
      },
    ],
  },
  summaryFields : {
    afterUsingTheTool: '',
    futureTasks: {
      task_1: '',
      task_2: '',
      task_3: '',
    },
  } 
};



export default function inputsReducer(state = localStorage.getItem("_binddata") ? JSON.parse(localStorage.getItem("_binddata")) : initialUserInputsState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_SUMMARY_FIELDS: {
      const { target, isObj } = action.payload;
      const { name, value } = target;

      const updatedSummaryFields = isObj ? 
      { ...state.summaryFields, futureTasks: { ...state.summaryFields.futureTasks, [name] : value}} :
      { ...state.summaryFields, [name]: value }

      updateDocumentByPath( getCookie("currentDocId"), value, isObj ? `summaryFields.futureTasks.${name}` : `summaryFields.${name}` );
      return { ...state, summaryFields : updatedSummaryFields };
    }
    case ACTIONS.UPDATE_PAGE_1: {
      const { name, value } = action.payload;
      return { ...state, page_1: { ...state.page_1, [name]: value } };
    }
    case ACTIONS.UPDATE_PAGE_2: {
      const { name, value } = action.payload;
      return { ...state, page_2: { ...state.page_2, [name]: value } };
    }
    case ACTIONS.UPDATE_PAGE_3: {
      const { payloadValues, infoItemId } = action.payload;
      let { name, value } = payloadValues;
      if(!infoItemId || !name) return state;

      const updatedPage_3 = state.page_3.infoItemsArr.map((infoItem, i) => {
        if (infoItem.infoItemId !== infoItemId) return infoItem;
          if (infoItem.factSource) {
            if(infoItem.radioGroup_1 === 'השערה' || value === "השערה") {
              delete infoItem.factSource;
              deleteDocsValue( getCookie("currentDocId"), `page_3.infoItemsArr.${[i]}.factSource` );
            }
          }
          infoItem[name] = value;
          return infoItem;
      })
      updateDocumentByPath( getCookie("currentDocId"), updatedPage_3, `page_3.infoItemsArr` );
      return { ...state, page_3: {...state.page_3, infoItemsArr : updatedPage_3} };
    }
    case ACTIONS.UPDATE_CHAIN: {
      const { chainId, target, itemIndex, changeAction, page } = action.payload;
      const { name, value } = target;

      const updatedChains = state[page].chains.map((chain) => {
        if (chain.chainId !== chainId) return chain;
          switch (changeAction) {
          case 'setChainName' : { chain[name] = value; return chain; }
          case 'setClassification' : { value === 'NOVALUE' ? chain[name] = '' : chain[name] = value; return chain; }
          case 'setCIClassification' : {
            chain.chainItems[itemIndex][name] = value; 
            
            return chain; 
          }
          default: { return chain }
        }
      });
      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedChains,
        `${page}.chains`
      );
      return { ...state, [page]: { ...state[page], chains: updatedChains } };
    }
    case ACTIONS.ADD_CHAIN_ITEM: {
      const { chainId, page } = action.payload;

      const updatedChains = state[page].chains.map((chain) => {
        if (chain.chainId === chainId) {
          chain.chainItems.push(" ");
        }
        return chain;
      });
      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedChains,
        `${page}.chains`
      );
      return { ...state, [page]: { ...state[page], chains: updatedChains } };
    }
    case ACTIONS.SET_ACTIVE_INFO_ITEM: {
      const infoItemObj = action.payload;
      return { ...state, page_3: { ...state.page_3, activeInfoItem: infoItemObj } };
    }
    case ACTIONS.ADD_INFO_ITEM: {
      const { infoItemId, page, addedFromCloud, infoItemData } = action.payload;
      const page_3InfoItems = [...state.page_3.infoItemsArr];
      const currentCloudInfoItems = addedFromCloud ? [...state[page].cloud] : null;
      const emptyInfoItem = {infoItemId : infoItemId, radioGroup_1: 'השערה', infoItemIndex: page_3InfoItems.length +1};

      if (addedFromCloud) {
        const infoItemObj = {
          infoItemId: infoItemId,
          radioGroup_1: 'השערה',
          infoItemIndex: page_3InfoItems.length +1,
          ...infoItemData
        }
        currentCloudInfoItems.push(infoItemObj)
        page_3InfoItems.push(infoItemObj)
        updateDocumentByPath( getCookie("currentDocId"), currentCloudInfoItems, `${page}.cloud` );
      } else {
        page_3InfoItems.push(emptyInfoItem)
      }
      const updatedInfoItems = page_3InfoItems.map((infoItem, i) => {
        infoItem.infoItemIndex = i + 1;
        return infoItem;
      })
      updateDocumentByPath( getCookie("currentDocId"), updatedInfoItems, `page_3.infoItemsArr` );

      return addedFromCloud ? 
      {...state, page_3: { ...state.page_3, infoItemsArr : updatedInfoItems }, [page] : { ...state[page], cloud: currentCloudInfoItems }} : 
      { ...state, page_3: { ...state.page_3, infoItemsArr : updatedInfoItems, activeInfoItem: emptyInfoItem } };
    }
    case ACTIONS.REMOVE_INFO_ITEM: {
      const { infoItemId, infoItemIndex } = action.payload;
      let infoItemToSetActive = state.page_3.infoItemsArr.filter((infoItem , i) => {
        if( infoItemIndex === 1 && i === 1) return infoItem;
        else if (infoItem.infoItemIndex === (infoItemIndex - 1)) return infoItem;
      })
      const newInfoItems = state.page_3.infoItemsArr.filter((infoItem) => infoItem.infoItemId !== infoItemId )
      const updatedInfoItems = newInfoItems.map((infoItem, i) => {
        infoItem.infoItemIndex = i + 1;
        if (Array.isArray(infoItemToSetActive) && infoItem.infoItemId === infoItemToSetActive[0].infoItemId) infoItemToSetActive = infoItem;
        return infoItem;
      })
      updateDocumentByPath( getCookie("currentDocId"), updatedInfoItems, `page_3.infoItemsArr` );
      return {
        ...state,
        page_3: { 
          ...state.page_3, 
          infoItemsArr: updatedInfoItems,  
          activeInfoItem: typeof infoItemToSetActive === 'object' && infoItemToSetActive !== null ? infoItemToSetActive : updatedInfoItems[0]
        }
      };
    }
    case ACTIONS.ADD_CHAIN: {
      const { payload: page } = action
      const chainsCopy = state[page].chains;
      const blankChain = {
        chainId: `${Date.now()}${Math.floor(Math.random() * 999999)}`,
        chainName: "",
        chainItems: [" ", " ", " "],
      };
      chainsCopy.push(blankChain);
      const updatedPage = { ...state[page], chains: chainsCopy };

      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedPage,
        page
      );
      return { ...state, [page]: updatedPage };
    }
    case ACTIONS.REMOVE_CHAIN: {
      const { chainId, page } = action.payload;
      const cloudCopy = state[page].cloud;
      const chainsCopy = state[page].chains.filter((chain) => {
        if (chain.chainId === chainId) {
          chain.chainItems.forEach((chainItem) => {
            if (chainItem !== " ") {
              cloudCopy.push(chainItem);
            }
          });
        } else {
          return chain;
        }
      });
      const updatedPage = {
        ...state[page],
        cloud: cloudCopy,
        chains: chainsCopy,
      };

      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedPage,
        page
      );
      return { ...state, [page]: updatedPage };
    }
    case ACTIONS.DUPLICATE_CHAIN: {
      const { chainId , page } = action.payload;
      const newInfoItems = [...state.page_3.infoItemsArr]
      const chainToDuplicate = state[page].chains.filter((chain) => chain.chainId === chainId );
      const duplicateChainItems = chainToDuplicate[0].chainItems.map((chainItem) => {
        if(typeof chainItem !== 'object') return " ";
        const duplicateChainItem = {...chainItem, infoItemId: `${Date.now()}${Math.floor(Math.random() * 999999)}`};
        newInfoItems.push(duplicateChainItem)
        return duplicateChainItem;
      })

      const updatedInfoItems = newInfoItems.map((infoItem, i) => {
        infoItem.infoItemIndex = i + 1;
        duplicateChainItems.forEach((_, j) => {
          if (duplicateChainItems[j].infoItemId === infoItem.infoItemId) {
            if(typeof duplicateChainItems[j] === "object") duplicateChainItems[j].infoItemIndex = i + 1
          }
        })
        return infoItem;
      })
      
      const duplicateChain = {...chainToDuplicate[0], chainId: `${Date.now()}${Math.floor(Math.random() * 999999)}`, chainItems: duplicateChainItems}
      
      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedInfoItems,
        "page_3.infoItemsArr"
      );
      updateDocumentByPath(
        getCookie("currentDocId"),
        [...state[page].chains, duplicateChain],
        `${page}.chains`
      );
      return { ...state, page_3: { ...state.page_3, infoItemsArr: updatedInfoItems} , [page] : { ...state[page], chains: [...state[page].chains, duplicateChain] } };
    }
    case ACTIONS.SET_DOCUMENT_STATE: {
      const { payload } = action;
      return { ...payload };
    }
    case ACTIONS.UN_CLASSIFY_CHAIN: {
      const { chainId, classification, page } = action.payload;
      const updatedChains = state[page].chains.map((chain) => {
        if (chain.chainId === chainId) {
          delete chain[classification];
        }
        return chain;
      });

      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedChains,
        `${page}.chains`
      );
      return { ...state, [page]: { ...state[page], chains: updatedChains } };
    }
    case ACTIONS.SET_REQUESTED_ITEMS: {
      const { classify, page, mainArr, innerArr } = action.payload;
      if (!mainArr || !Array.isArray(state[page][mainArr])) return;
      const relevantInfoItems = state.page_3.infoItemsArr.filter(
        (infoItem) => infoItem.radioGroup_2 === classify
      );

      // Remove irrelevant items then updated items (cloud & containers) -->
      const newCloud = state[page].cloud
        .filter((cloudItem) => {
          const isRelevant = relevantInfoItems.some(
            (item) => item.infoItemId === cloudItem.infoItemId
          );
          if (isRelevant) return cloudItem;
        })
        .map((cloudItem) => {
          relevantInfoItems.forEach((newInfoItem) => {
            if (cloudItem.infoItemId === newInfoItem.infoItemId) {
              for (const key in newInfoItem) {
                if (cloudItem[key] !== newInfoItem[key]) {
                  cloudItem[key] = newInfoItem[key];
                }
              }
            }
          });
          return cloudItem;
        });
        
      const newContainers = state[page][mainArr].map((innerContainer) => {
          const newContainer = innerContainer[innerArr].filter((infoItem) => {
            if (typeof infoItem !== 'object') { return infoItem = " " }
            const isRelevant = relevantInfoItems.some(
              (item) => item.infoItemId === infoItem.infoItemId
            );
            if (isRelevant) return infoItem;
          });
          
          innerContainer[innerArr] = newContainer;
          if ( mainArr === "chains" && innerContainer[innerArr].length < 3 ) {
            innerContainer[innerArr].push(" ")
          }
          return innerContainer;
      });

      const updatedContainers = newContainers.map((innerContainer) => {
          const newContainer = innerContainer[innerArr].map((infoItem) => {
            relevantInfoItems.forEach((newInfoItem) => {
              if (infoItem.infoItemId === newInfoItem.infoItemId) {
                for (const key in newInfoItem) {
                  if (infoItem[key] !== newInfoItem[key]) {
                    infoItem[key] = newInfoItem[key];
                  }
                }
              }
            });
            return infoItem;
          });

          innerContainer[innerArr] = newContainer;
          return innerContainer;
      });

      let updatedChains;
      if(mainArr === 'chains') {
        updatedChains = updatedContainers.map((chain) => {
          if(chain.chainItems.length < 3) {
            for (let i = 0; i < 3; i++) {
              if(chain.chainItems.length >= 3) break;
              if(typeof chain.chainItems[i] !== 'object') {
                chain.chainItems.push(" ")
              }
            }
          }
          return chain;
        });
      }
      
      // Classify new items to cloud -->
      relevantInfoItems.forEach((newInfoItem) => {
        let itemAlreadyClassify = false;
        newCloud.forEach((cloudItem) => {
          if (newInfoItem.infoItemId === cloudItem.infoItemId) {
            itemAlreadyClassify = true;
          }
        });
        updatedContainers.forEach((innerContainer) => {
              innerContainer[innerArr].forEach((infoItem) => {
              if (newInfoItem.infoItemId === infoItem.infoItemId) {
                itemAlreadyClassify = true;
              }
            });
        });
        if (!itemAlreadyClassify) newCloud.push(newInfoItem);
      });

      const updatedPage = {
        ...state[page],
        cloud: newCloud,
        [mainArr]: mainArr === 'chains' ? updatedChains : updatedContainers,
      };
      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedPage,
        page
      );
      return { ...state, [page]: updatedPage };
    }
    case ACTIONS.SET_DRAGGED_ITEMS: {
      const {
        draggedData,
        page,
        mainArr,
        innerArr,
        chainId,
        droppedIndex,
      } = action.payload;
      const cloudCopy = state[page].cloud;
      const containerArrCopy = state[page][mainArr];
      
      switch (true) {
        case chainId === "ענן": {
            cloudCopy.forEach((cloudItem, i) => {
              if (cloudItem.infoItemId === draggedData.infoItemId) {
                cloudCopy.splice(i, 1);
              }
            });
              containerArrCopy.forEach((innerContainers) => {
                innerContainers[innerArr].forEach(
                  (innerContainerInfoItem, i) => {
                    if ( innerContainerInfoItem.infoItemId === draggedData.infoItemId ) {
                      innerContainers[innerArr].length > 3 ||  mainArr !== "chains" ?
                      innerContainers[innerArr].splice(i, 1)
                      : innerContainers[innerArr].splice(i, 1, " ");
                    }
                  }
                );
              });

            cloudCopy.push(draggedData);
        } break;
        case chainId !== "ענן": {
          let replaceItemFromCloud = false, draggedItemPos = {};
            cloudCopy.forEach((cloudItem, i) => {
              if (cloudItem.infoItemId === draggedData.infoItemId) {
                replaceItemFromCloud = true;
                cloudCopy.splice(i, 1);
              }
            });
            
            containerArrCopy.forEach((innerContainer) => {
              if(!droppedIndex && droppedIndex !== 0) {
                innerContainer[innerArr].forEach((innerContainerInfoItem, i) => {
                  if(innerContainerInfoItem.infoItemId === draggedData.infoItemId) {
                    innerContainer[innerArr].splice(i, 1);
                  }
                })
                if(innerContainer.slug === chainId) {
                  innerContainer[innerArr].push(draggedData);
                }
              }
              if(droppedIndex || droppedIndex === 0) {
                if ( !replaceItemFromCloud ) {
                  innerContainer[innerArr].forEach((innerContainerInfoItem, i) => {
                    if(innerContainerInfoItem.infoItemId === draggedData.infoItemId) {
                      draggedItemPos.chainId = innerContainer.chainId
                      draggedItemPos.index = i;
                    }
                  })
                } else {
                  if(innerContainer.chainId === chainId) {
                    let replacedItem = innerContainer[innerArr].splice(droppedIndex, 1 , draggedData)
                    if(typeof replacedItem[0] === 'object') cloudCopy.push(replacedItem[0])
                  }
                }
              }
            });
            if(!replaceItemFromCloud && (droppedIndex || droppedIndex === 0)) {
              let replacedItem;
              containerArrCopy.forEach((innerContainer) => {
                if(innerContainer.chainId === chainId) {
                  replacedItem = innerContainer[innerArr].splice(droppedIndex, 1 , draggedData)
                }
              })
              containerArrCopy.forEach((innerContainer) => {
                if(innerContainer.chainId === draggedItemPos.chainId) {
                  innerContainer[innerArr].splice(draggedItemPos.index, 1 , replacedItem[0])
                }
              })
            }

        } break;
        default: break;
      }
      const updatedPage = {
        ...state[page],
        cloud: cloudCopy,
        [mainArr]: containerArrCopy,
      };
      updateDocumentByPath(
        getCookie("currentDocId"),
        updatedPage,
        page
      );
      return { ...state, [page]: updatedPage };
    }

    default: {
      return state;
    }
  }
}