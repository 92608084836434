import React  from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import RoutesConfiguration from './config';
import AppRoutes from './routes-app';
import Login from '../pages/login/login';
import { getCookie } from '../../../firebase/helpers';

export default function AppRouter() {
    return (
      <Switch>
        <Route exact path='/' component={Login} >
          {getCookie('userId') ?
            <Redirect to="/intro" /> :
            <Redirect to="/sign-in" />}
        </Route>
        <AppRoutes configuration={RoutesConfiguration} />
      </Switch>
    )
}