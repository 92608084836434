import React, { useState } from 'react';
import { ExclamationTriangle, Plus } from 'react-bootstrap-icons';
import FormControl from 'react-bootstrap/esm/FormControl';
import ReactTooltip from 'react-tooltip';
import CircleButton from '../circle-button';
import InfoItemCapsule from '../infoItemCapsule';
import styles from './descrip.module.css'
import InputModal from '../input-modal';
import toast from 'react-hot-toast';
import { appConfiguration } from '../../../store/configuration';
import DescriptCloudForm from '../descripts-cloud-form';
import { figureToastSettings } from '../../containers/pages/quest-pages/helpers';

export default function DescriptsCloud(props) {
    const { requestedInfoItems, action, addInfoItemAction, cloudClass, innerColumns, shortCloud } = props
    const modalInitialValues = { value: '', show: false }
    const localStateInitialValues = { radioGroup_1: 'השערה' }
    const [addInfoItem, setAddInfoItem] = useState(false)
    const [localState, setLocalState] = useState(localStateInitialValues)
    const [inputModalState, setInputModalState] = useState(modalInitialValues)


    function handleChange({target}) {
        const { name, value } = target;
        if(value === 'השערה' && localState.factSource) {
            delete localState.factSource
        }
        setLocalState({
            ...localState,
            [name] : value
        })
    }
    function handleSubmit() {
        addInfoItemAction(localState)
        setLocalState(localStateInitialValues); 
        setInputModalState(modalInitialValues)
        setAddInfoItem(false)
    }
    function handleModalChange({ target }) {
        const { value } = target;
        setInputModalState({...inputModalState, value: value})
    }
    function handleModalSubmit() {
        if(inputModalState.value === localState.factSource || (!localState.factSource && !inputModalState.value.length)) return setInputModalState({...inputModalState, show: false});
        const toastMessage = inputModalState.value.length ? appConfiguration.toastMessages.factAddeddFromCloud : appConfiguration.toastMessages.factRemove
        toast.success(toastMessage, figureToastSettings(inputModalState.value.length));
        setLocalState({ ...localState, radioGroup_1: 'עובדה אמפירית מגובה במקור', factSource : inputModalState.value })
        setInputModalState({...inputModalState, show: false})
    }
    function handleModalAbort() {
        setInputModalState(inputModalState.value.length ? {...inputModalState, show: false} : modalInitialValues)
    }
    function abortInfoItemAddition() {
        setLocalState(localStateInitialValues); 
        setInputModalState(modalInitialValues)
        setAddInfoItem(false)
    }
    function dragStartHandler(e, item) {
        e.dataTransfer.setData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain', JSON.stringify(item))
        ReactTooltip.hide()
    }
    function dropHandler(e) {
        e.preventDefault()
        const item = e.dataTransfer.getData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain')
        action(JSON.parse(item), 'ענן')
    }
    function dragOverHandler(e) {
        e.preventDefault()
    }

    return ( addInfoItem ?
            <>  <InputModal
                    handleSubmit={handleModalSubmit}
                    onAbort={handleModalAbort}
                    onHide={() => setInputModalState({...inputModalState, show: false})}
                    show={inputModalState.show}
                    modalTitle={<p className={'md-text p-0 m-0 font-weight-bold'}>הוספת מקור עובדה</p>}>
                    <FormControl className={`modalInput outlineOff sm-text`} defaultValue={localState.factSource} name={'factSource'} onChange={(e) => handleModalChange(e)} placeholder={'הקלידי לינק או טקסט חופשי כאן'} autoComplete={'off'}/>
                </InputModal>
                <DescriptCloudForm handleChange={handleChange} localState={localState} inputModalState={inputModalState} setInputModalState={setInputModalState} shortCloud={shortCloud} handleSubmit={handleSubmit} abortInfoItemAddition={abortInfoItemAddition}/> 
            </> :
        <div className={`${styles.descriptsCloud}`} onDrop={(e) => dropHandler(e)} onDragOver={(e) => dragOverHandler(e)}>
            <span className={`${styles.cloudComment} xs-text textRed font-weight-bold`}><ExclamationTriangle className={'ml-1'} size={14}/> פריטים שמסווגים כ{cloudClass} </span>
            <div className={`${styles.descriptsCloudInner} dynamicShadow scrollbar-off`} style={{gridTemplateColumns: `repeat(${innerColumns}, 1fr`, gridTemplateRows: `repeat(auto-fill, minmax(min-content , 25px))`}}>
                {requestedInfoItems.map((item) => <InfoItemCapsule key={item.infoItemId} item={item} dragStartHandler={dragStartHandler} innerColumns={innerColumns}/> )} 
            </div>
            <div className={`${styles.buttonWrapper}`}>
                <CircleButton 
                    action={() => setAddInfoItem(true)}
                    buttonColor={'#82BB2D'}
                    textUnder={false}
                    text={'הוספת פריט מידע'}
                    iconComponent={<Plus color={'white'} size={32}/>}
                    triggerBooleanState={true}
                    additionalClasses={'sm-text'}
                    containerAdditionalStyle={{justifyContent: 'center', marginTop: '10px'}} />
            </div>
        </div>

    )
}