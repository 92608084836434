import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import DescriptsCloud from "../../../../ui-components/descripts-cloud";
import { useHistory } from "react-router-dom";
import Chain from "../../../../ui-components/chain";
import NavbarApp from "../../../../ui-components/nav-bar";
import InfoModal from "../../../../ui-components/modal";
import { addChain, addInfoItem, removeChain, setDraggedItems, setRequestedItems } from "../../../../../store/inputs-reducer/inputs.actions";
import { useSelector, useDispatch } from "react-redux";
import vector_5 from "../../../../../assets/Vector_5.png";
import QuestProgress from "../../../../ui-components/quest-progress";
import MainLogo from "../../../../ui-components/mainLogo";
import { ChatSquareText, ChevronLeft, ChevronRight, Info, Plus } from "react-bootstrap-icons";
import QuestFrame from "../../../../ui-components/quest-frame";
import CircleButton from "../../../../ui-components/circle-button";
import { getCookie } from "../../../../../firebase/helpers";
import { findEmptyChain } from "../helpers";
import { appConfiguration } from "../../../../../store/configuration";
import toast, { Toaster } from "react-hot-toast";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import QuestionBank from "../../../visual-containers/questions-bank";


export default function QuestPage5() {
  const stateInputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const history = useHistory();
  const chainsEndRef = useRef(); 

  function setRequestedItemsAction() {
    const payload = {
      classify: "סיבה לבעיה",
      page: "page_5",
      mainArr: "chains",
      innerArr: "chainItems",
    };
    dispatch(setRequestedItems(payload));
  }

  function setDraggedItemsAction(data, chainId, itemIndex) {
    const payload = {
      draggedData: data,
      page: "page_5",
      mainArr: "chains",
      innerArr: "chainItems",
      chainId: chainId,
      droppedIndex: itemIndex,
    };
    dispatch(setDraggedItems(payload));
  }

  function addInfoItemAction(infoItemNewData) {
    const newInfoItemId = Date.now() + (Math.random() * 100000).toFixed();
    infoItemNewData.radioGroup_2 = "סיבה לבעיה";
    dispatch(
      addInfoItem({
        infoItemId: newInfoItemId,
        page: "page_5",
        addedFromCloud: true,
        infoItemData: infoItemNewData,
      })
    );
  }

  function checkEmptyChains(duplicateChain) {
    const emptChainArr = findEmptyChain(stateInputs, 'page_5')
    if(emptChainArr[0]) {
      const delayOverlay = stateInputs.page_5.chains.length < 10 ? stateInputs.page_5.chains.length * 200 : stateInputs.page_5.chains.length * 50;
      scrollTo(false, emptChainArr[1])
      setTimeout(() => setShowOverlay([true, emptChainArr[1]]), delayOverlay)
      setTimeout(() => setShowOverlay(false), 5500)
    } else {
      return duplicateChain ? true : addChainAction()
    };
  }

  function addChainAction() {
      dispatch(addChain("page_5"));
      scrollTo(true)
  }

  function scrollTo(ref, eleID) {
    setTimeout(() => {
      ref ? 
      chainsEndRef.current.scrollIntoView({ behavior: "smooth" }) :
      document.getElementById(eleID).scrollIntoView({ behavior: "smooth" })
      ;
    }, 100);
  }

  function checkAndRemoveEmptyChains() {
    const emptyChainsIdToRemove = []
    stateInputs.page_5.chains.forEach((chain) => {
      let itemFound;
      chain.chainItems.forEach((chainItem) => {
        if (typeof chainItem === 'object') {
          itemFound = true;
        }
      })
      if (!itemFound) emptyChainsIdToRemove.push(chain.chainId)
    })
    if(emptyChainsIdToRemove.length === stateInputs.page_5.chains.length) emptyChainsIdToRemove.pop();
    emptyChainsIdToRemove.forEach((chainIdToRemove) => dispatch(removeChain({chainId: chainIdToRemove, page: 'page_5'})))

    if((stateInputs.page_5.chains.length - emptyChainsIdToRemove.length) <= 1) {
      history.push("./page-6"); 
    } else {
      history.push("./summary-page");
    }
  }

  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    setRequestedItemsAction();

    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
    return () => {
      toast.dismiss()
    }
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
  }, [stateInputs]);
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={ appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_5" ).title }/>
      <div style={{width: 'fit-content', position: 'fixed', zIndex: '9999999', bottom: '50px', left: '50%', transform: 'translateX(-50%'}}><Toaster position="bottom-center" reverseOrder={false} toastOptions={{duration: 5000, style: { minWidth: '350px'}}}/></div>
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_5} className={'vector_5'} alt={""} />
            </div>
            <div className={"attachToBottom"}>
              <MainLogo />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={'main-content'}>
              <InfoModal show={modalShow} onHide={() => setModalShow(false)} title={'בנק שאלות מנחות'}>
                <QuestionBank />
              </InfoModal>
              <QuestFrame orderNum={6} frameType={"order"} additionalStyles={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: '370px'}}>
                <p className={"font-weight-bold textBlue p-0 mb-3 md-text"}>
                  בצד שמאל מופיעות <span className={'textGreen md-plus-text'}>הסיבות לבעיה</span> שהקלדת במסך פריטי המידע.
                  <br />
                  <span className={"text-muted font-weight-bold attachedText sm-text"}>
                    גררי כל סיבה למקומה המתאים וצרי את
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 2000 }}
                      overlay={
                        <Tooltip id="tooltip" className={'d-flex justify-content-center'} style={{ width: "450px", opacity: "1" }}>
                          <p className={'sm-text fontFamily p-0 m-0 textBlue font-weight-bold'}>
                            רצף פעולות אנושיות שיש ביניהן קשר סיבתי,
                            החל מנקודת הסיכוי להיווצרות הסיכון ועד לרגע הפגיעה.
                            למשל: לרוב ישנן כמה שרשראות אפשריות המובילות אל הפגיעה והאתגר יהיה בבנייתן על בסיס נתונים וזיהוי הקשר הסיבתי.
                          </p>
                          <span className={'sm-text fontFamily textGreen font-weight-bold'}> להרחבה לחצי על הכפתור</span>
                            <CircleButton action={() => console.log("כפתור למדריך עמ' 5")}
                              title={'קישור למדריך'}
                              buttonColor={'#82BB2D'}
                              iconComponent={<Info color={'white'} size={16}/>}
                              containerAdditionalStyle={{ display: 'inline-block', marginRight: '5px' }}
                              iconCircleAdditionalStyle={{ width: 'fit-content', height: 'fit-content' }}/>
                        </Tooltip>
                      }
                    >
                      <span className={'textGreen cursor-help'}> השרשרת הסיבתית <span className={'textGreen ml-1'}><ChatSquareText size={14} /></span> </span>
                    </OverlayTrigger>
                     לפגיעה בצורה לינארית ככל הניתן {"(א' גורם ל -> ב' שגורם ל -> ג' וכו')"}.
                    {"\n"}
                    ניתן גם להוסיף גורם נוסף לשרשרת, ולסמן האם חסר לך מידע בנושא או אם זו
                    עובדה או השערה. בכל עת ניתן לפתוח שרשרת חדשה, הנובעת מגורם
                    חדש,{"\n"}
                    על ידי לחיצה על "שרשרת חדשה" ולשיים כל שרשרת. {" "}
                    <span
                      className={"generalBlueLink textBlue"}
                      onClick={() => setModalShow(true)}>בנק שאלות מנחות ממתין כאן.
                    </span>
                  </span>
                </p>
                <Row className={"flex-grow-1"}>
                  <Col className={'position-relative d-flex'} xl={7} lg={7} md={6} sm={6}>
                    <div className={"d-flex align-items-start custom-scrollbar pb-2"} style={{ position: 'absolute', overflowX: "auto", overflowY: "hidden", width: '85%', height: '100%'}}>
                        {stateInputs.page_5.chains.map((chain, i) => {
                          return (
                            <React.Fragment key={chain.chainId}>
                                <Chain
                                  itemKey={chain.chainId}
                                  domID={chain.chainId}
                                  action={setDraggedItemsAction}
                                  checkEmptyChains={checkEmptyChains}
                                  showOverlay={showOverlay}
                                  index={i}
                                  page={"page_5"}
                                  scrollTo={scrollTo}
                                  {...chain}
                                />
                            </React.Fragment>
                          );
                        })}
                        <div ref={chainsEndRef}></div>
                    </div>
                    
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1}>
                    <CircleButton
                      action={checkEmptyChains}
                      buttonColor={'#82BB2D'}
                      textUnder={true}
                      text={'שרשרת חדשה'}
                      iconComponent={<Plus color={'white'} size={32}/>}
                      triggerBooleanState={false}
                      additionalClasses={'sm-text'}
                      containerAdditionalStyle={{margin: '0 auto'}}
                      textAdditionalStyle={{whiteSpace: 'nowrap', marginTop: '15px'}} />
                  </Col>
                  <Col className={'position-relative h-100'} xl={4} lg={4} md={5} sm={5}>
                    <DescriptsCloud
                      requestedInfoItems={stateInputs.page_5.cloud}
                      action={setDraggedItemsAction}
                      addInfoItemAction={addInfoItemAction}
                      cloudClass={'סיבה לבעיה'}
                      innerColumns={2}
                      shortCloud={true}
                    />
                  </Col>
                </Row>
              </QuestFrame>
            </div>
            <div className={'bottom-content'}></div>
          </div>
          <div className={'left-side-strip'}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={4} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./page-4")}/>
              <ChevronLeft className={"browseArrows"} onClick={checkAndRemoveEmptyChains}/>
            </div>
          </div>
        </div>
      </div>
      <div className={"downer-line"}></div>
    </div>
  );
}
