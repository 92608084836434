import React, { useState } from 'react';
import { Files, Trash } from 'react-bootstrap-icons'; 
import CircleButton from '../circle-button';
import PillButton from '../pillButton';
import styles from './item.module.css';

export default function DocumentItem(props) {
    const { data, itemKey, action, duplicateDoc, removeDoc, handleChange, getDocs, windowWidth } = props;
    const [changeDocName, setChangeDocName] = useState(false)

    function handleBlur({ value }) {
      if(value !== data.docName) getDocs()
      setChangeDocName(false)
    }

    return (
      <tr key={itemKey} id={itemKey} className={styles.docItem_tr} >
        <td>
          <div className={'d-flex'}>
            <div className={'flex-grow-1'}>
                {changeDocName ?
                  <input
                    className={`d-inline-block bg-transparent sm-text w-100 ${styles.input_tr}`}
                    name={'docName'}
                    onChange={(e) => handleChange(e, data.docId)}
                    onBlur={(e) => handleBlur(e.target)}
                    defaultValue={data.docName}
                    autoFocus={true}
                    placeholder={'הקלידי שם למסמך ... '}/>
                    : <p className={'d-inline-block m-0 sm-text doc-input-field'} onClick={() => setChangeDocName(true)}>{data.docName || "הקלידי שם למסמך"}</p>}
              </div>
              <div className={'d-flex'}>
                <PillButton action={() => action(data.data, data.docId)} buttonColor={'#82BB2D'} text={'כניסה'} containerAdditionalStyle={{ padding: '0 10px'}} additionalClasses={'xs-text'}/>
                {windowWidth < 1200 && <PillButton action={() => duplicateDoc(data.data, data.docName)} buttonColor={'#82BB2D'} text={'שכפול'} containerAdditionalStyle={{ padding: '0 10px'}} additionalClasses={'xs-text mx-1'}/>}
                {windowWidth < 1200 && <PillButton action={() => removeDoc(data.docId)} buttonColor={'#e84e0e'} text={'מחיקה'} containerAdditionalStyle={{ padding: '0 10px'}} additionalClasses={'xs-text'}/>}
              </div>
          </div>
        </td>
        <td title={data.created}><p className={'m-0 xs-text'}>{data.created}</p></td>
        <td title={data.updated}><p className={'m-0 xs-text'}>{data.updated}</p></td>
        {windowWidth > 1200 && <td className={"d-flex align-items-center"} >
          <CircleButton
            action={() => duplicateDoc(data.data, data.docName)}
            buttonColor={'#82BB2D'}
            iconComponent={<Files color={'white'} size={16}/>}
            text={'שכפול'}
            additionalClasses={'xs-text'}
            containerAdditionalStyle={{width: '50%'}}
          />
          <CircleButton
            action={() => removeDoc(data.docId)}
            buttonColor={'#e84e0e'}
            iconComponent={<Trash color={'white'} size={16}/>}
            text={'מחיקה'}
            additionalClasses={'xs-text'}
            containerAdditionalStyle={{width: '50%'}}
          />
        </td>}
      </tr>
    )
  }