import React  from 'react';
import styles from './progress.module.css'
import '../../../App.css'
import { appConfiguration } from '../../../store/configuration';

export default function QuestProgress(props) {
    const appPages = appConfiguration.questPagesConfig.appPages.orderedPages;
    const { pageNumber } = props
 
    return (
        <div className={styles.questProgressWrap}>
        { appPages.map((_,i) => {
            if(i < pageNumber) {
                return <div key={i} className={`bgBlue ${styles.emptyProgressItem}`}></div>
            } else if (i === pageNumber) {
                return <React.Fragment key={i}>
                    <div key={i} className={`${styles.emptyProgressItem} ${styles.activeProgressItem} bgGreen`}>
                        <div className={`bgGreen font-weight-bold ${styles.activeTag}`}>את נמצאת כאן</div>
                    </div>
                </React.Fragment>
            } else { return <div key={i} className={styles.emptyProgressItem}></div> }
        })}
        </div>
    )
}