import { ACTIONS } from "../actions.config";


const initialSummaryState = {

    missingInformationItems: [],
    infoItemsInPercentage: {
        facts: null,
        hypotheses: null
    },
    mainClassifications: {
        thereIsSeverityItems: false,
        thereIsFrequencyScopeItems: false,
        thereIsFrequencyAmountItems: false,
    }
}

export default function summaryReducer(state = initialSummaryState, action) {

    switch(action.type) {
        case ACTIONS.FIND_MISSING_INFORMATION_ITEMS: {
            const { stateInputs } = action.payload;
            if(!stateInputs) return state;
            const filteredItems = stateInputs.page_3.infoItemsArr.filter((infoItem) => infoItem.radioGroup_2 === 'לא יודעת כרגע')

            return { ...state, missingInformationItems: filteredItems }
        }
        case ACTIONS.GET_INFO_ITEMS_IN_PERCENTAGE: {
            const { stateInputs } = action.payload;
            if(!stateInputs) return state;

            const updatedInfoItemsPercentage = {
                fact : _percentage(stateInputs.page_3.infoItemsArr.filter((infoItem) => infoItem.radioGroup_1 === 'עובדה אמפירית מגובה במקור').length, stateInputs.page_3.infoItemsArr.length).toFixed(0),
                hypotheses : _percentage(stateInputs.page_3.infoItemsArr.filter((infoItem) => infoItem.radioGroup_1 === 'השערה').length, stateInputs.page_3.infoItemsArr.length).toFixed(0) 
            }

            function _percentage(partialValue, totalValue) {
                return (100 * partialValue) / totalValue;
            }
            return { ...state, infoItemsInPercentage: updatedInfoItemsPercentage }
        }
        case ACTIONS.CHECK_MAIN_CLASSIFICATIONS: {
            const { stateInputs } = action.payload;
            if(!stateInputs) return state;

            const mainClassifications = {
                thereIsSeverityItems: stateInputs.page_4.containers.find((container) => container.slug === 'חומרה').items.length > 0,
                thereIsFrequencyScopeItems: stateInputs.page_4.containers.find((container) => container.slug === 'כמות הפגיעות').items.length > 0,
                thereIsFrequencyAmountItems: stateInputs.page_4.containers.find((container) => container.slug === 'היקף הפעילות').items.length > 0,
            }

            return { ...state, mainClassifications: mainClassifications }
        }
        case ACTIONS.INITIAL_SUMMARY_STATE: return { ...initialSummaryState }
        
        default: {
            return state;
        }
    }
}

