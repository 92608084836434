import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import styles from './capsule.module.css'
import '../../../App.css'
import { appConfiguration } from "../../../store/configuration"

export default function InfoItemCapsule(props) {
    const { item, action, dragStartHandler, additionalClasses, additionalStyles = [], toolTip = true, itemDraggable = true, itemClickable = false, innerColumns} = props
    const {descriptText = '', infoItemId, infoItemIndex} = item;
    const [isHovered, setIsHovered] = useState(false)
    
    return (
        <div className={`${styles.capsuleWrap} ${additionalClasses} md-text`} style={{cursor: itemDraggable ? appConfiguration.userAgent.IExplorerAgent ? 'move' : 'grabbing' : 'pointer', flex: innerColumns == 2 ? '0 0 50%' : '0 0 25%', ...additionalStyles}}>
            <div
            className={`${styles.capsule} ${isHovered ? 'bgGreen' : 'bgBlue'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onDragStart={(e) => itemDraggable && dragStartHandler(e, item)}
            onClick={() => itemClickable && action(item)}
            data-tip={descriptText.length ? `<b>תיאור פריט המידע :</b> </br> ${descriptText}` : '<b>לא נרשם תיאור</b>'} 
            data-for={infoItemId}
            draggable > 
                מידע {infoItemIndex}
            </div>
            {toolTip && <ReactTooltip
            id={infoItemId}
            place={'bottom'}
            className={`text-muted baseColorToolTip sm-text border ${innerColumns && 'position-absolute'}`}
            effect={'solid'}
            html={true}
            multiline={true} />}
        </div>
    )
}