import React, { useEffect, useState } from "react";
import QuerySection from "../../../../ui-components/query-section";
import { useHistory } from "react-router-dom";
import NavbarApp from "../../../../ui-components/nav-bar";
import "../../../../../App.css";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import MainLogo from "../../../../ui-components/mainLogo";
import QuestProgress from "../../../../ui-components/quest-progress";
import vector_1 from "../../../../../assets/Vector_1.png";
import QuestFrame from "../../../../ui-components/quest-frame";
import { updatePage_1 } from "../../../../../store/inputs-reducer/inputs.actions";
import { getCookie, updateDocumentByPath } from "../../../../../firebase/helpers";
import { useSelector, useDispatch } from "react-redux";
import { appConfiguration } from "../../../../../store/configuration";

export default function QuestPage1() {
  const stateInputs = useSelector(state => state.inputs)
  const dispatch = useDispatch()
  const initialLocalState = {
    input_A: stateInputs.page_1.input_A ? stateInputs.page_1.input_A : "",
    input_B: stateInputs.page_1.input_B ? stateInputs.page_1.input_B : "",
  };
  const [localState, setLocalState] = useState(initialLocalState);
  const history = useHistory();

  function handleBlur({ target }) {
    const { value, name } = target;
    if (value === localState[name]) return;
    setLocalState({ ...localState, [name]: value });
    dispatch(updatePage_1({ name: name, value: value }));
    updateDocumentByPath( getCookie("currentDocId"), value, `page_1.${name}` );
  }

  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
  }, [stateInputs]);
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_1").title} />
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={"right-side-strip"}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_1} className={'vector_1'} alt={''}/>
            </div>
            <div className={"attachToBottom"}>
              <MainLogo />
            </div>
          </div>
          <div className={"main-content-wrapper"}>
                <div className={'main-content'}>
                    <QuestFrame orderNum={1} frameType={"order"} additionalStyles={{flexGrow: '1'}}>
                      <QuerySection type={"textarea"} value={localState.input_A} name={"input_A"} action={handleBlur} rows={4} question={<span className={'textGreen'}>מה הבעיה ?</span>} ph={'לדוגמא: השלכת פסולת בניין בשטחים פתוחים, זיהום אוויר, דריסה ע"י קורקינטים'} textTooltip={'שרשרת סיבתית של התנהגויות אנושיות הגורמות לנזק'}/>
                    </QuestFrame>
                    <QuestFrame orderNum={2} frameType={"order"} additionalStyles={{flexGrow: '1'}}>
                      <QuerySection type={"textarea"} value={localState.input_B} name={"input_B"} action={handleBlur} rows={4} question={"מה העלה את הנושא לסדר יומך ?"} ph={'לדוגמא: לחץ תקשורתי, הוראה מהמנכ"ל, פרואקטיביות שלך'}/>
                    </QuestFrame>
                    <QuestFrame frameType={"question"} additionalStyles={{marginTop: '10px'}}>
                      <p className={"font-weight-bold textBlue p-0 m-0 md-text"}>חשבי - כיצד הגורמים שפירטת מעלה עלולים להשפיע על תהליך העבודה שלך.</p>
                      <small className={"text-muted font-weight-bold sm-text"}>
                        <p className={'m-0 p-0'}>
                        הגורם שהעלה את הנושא לסדר יומך עלול להשפיע על תהליך העבודה. למשל:<br/>
                        - מצב בו המניע המרכזי הוא לחץ תקשורתי יוצר רצון להביא פתרונות מהירים, לעיתים על חשבון הבנת הבעיה לעומקה.<br/>
                        - מצב בו המניע הוא אסון חריג שהתרחש עלול להוביל להבלטת החריג והעצמת הסיכון. כלומר, שימת דגש רב יותר על מצבי הסכנה החריגים והסקת מסקנות על הכלל כולו מתוך החריג.

                        </p>
                        </small>
                    </QuestFrame>
                  </div>
                <div className={"bottom-content"}></div>
          </div>
          <div className={"left-side-strip"}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={0} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./home")}/>
              <ChevronLeft className={"browseArrows"} onClick={() => history.push("./page-2")}/>
            </div>
          </div>
        </div>
      </div>
      <div className={'downer-line'}></div>
    </div>
  );
}