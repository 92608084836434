import React, { useState } from "react";
import { withRouter, useHistory } from 'react-router-dom';
import { getFirebaseErrorMessage } from "../../../../firebase/commonUtils";
import styles from './login.module.css';
import '../../../../App.css';
import MainLogo from "../../../ui-components/mainLogo";
import logo2 from '../../../../assets/logo2.png';
import { setCookie } from "../../../../firebase/helpers";
import fire from "../../../../firebase";
import PillButton from "../../../ui-components/pillButton";

export default function Login() {
  const initialLocalState = {userName: '', password: '', fireErrors: ''}
  const [localState, setLocalState] = useState(initialLocalState)
  const history = useHistory()

  function navigate() {
    history.push('/intro')
  }

  function login(e) {
    e.preventDefault()
    fire.auth().signInWithEmailAndPassword(localState.userName, localState.password).then((response) => {
      setCookie('userId', response.user.uid)
      navigate()
    }).catch((error) => {
        let errorCode = error.code;
        setLocalState({...localState, fireErrors: getFirebaseErrorMessage(errorCode)})
    });
  }

  function updateInputValue(value, key) {
    setLocalState({
      ...localState, [key]: value
    });
  }
  return (
    <div className={styles.loginMain}>
      <div className={styles.pageWrapper}>
        <div className={styles.uppearStrip}>
          <div>
            <MainLogo />
          </div>
          <div className={'mr-auto'}>
            <img src={logo2} height={"80px"} alt={'מדיניות רגולציה'}/>
          </div>
        </div>
        <div className={styles.bottomStrip}>
          <div className={styles.pageHeader}>
            <h1>
                <span className={`textBlue font-weight-bold`}> המפתח </span> 
                <span className={`textGreen`}>לזיהוי בעיה</span>
            </h1>
          </div>
          <div className={'justify-self-center flex-grow-1 d-flex align-items-center'}>
            <div className={`${styles.boxWrapper}`}>
              <div className={styles.loginHeader}>
                <h3>ברוכים <span className={`textBlue font-weight-bold`}>הבאים</span></h3>
                <div className={styles.lineBottom}></div>
              </div>
              <form className={styles.loginForm}>
                <p className={`${styles.errorsMsg} textBlue`}>{localState.fireErrors}</p>
                <div className="form-group">
                  <label className={`${styles.formLabel} textBold`}>דואר אלקטרוני</label>
                  <input type="email" className={`${styles.formInput}`}
                    onChange={evt => updateInputValue(evt.target.value, 'userName')}
                    placeholder="דואר אלקטרוני" />
                </div>

                <div className="form-group">
                  <label className={`${styles.formLabel} textBold`}>סיסמה</label>
                  <input type="password" className={`${styles.formInput}`}
                    onChange={evt => updateInputValue(evt.target.value, 'password')}
                    placeholder="סיסמה" />
                </div>
                <div className={styles.loginFooter}>
                  {/* <div className={styles.topFooter}>
                    <input type="checkbox" onChange={evt => updateInputValue(evt.target.checked, 'rememberMe')}/>
                    <label className={`${styles.formLabel}`}>זכור אותי</label>
                  </div> */}

                  <div className={styles.bottomFooter}>
                    <PillButton
                      action={login}
                      buttonColor={'#82bb2d'}
                      text={'כניסה'}
                      containerAdditionalStyle={{width: '35%', height: '30px', margin: '0 auto',padding: '0% 10%'}}
                    />
                    <div>
                      <span className={'generalLink'} onClick={() => history.push('./sign-up')}>הרשמה</span> | <span className={`textGreen generalLink`} onClick={() => history.push('./forgot-password')}> שכחתי את הסיסמא </span>
                    </div>      
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={'downer-line mt-auto'}></div>
        </div>
      </div>
    </div>
  )
}

Login = withRouter(Login);