import { appConfiguration } from "../../../../store/configuration";

export function findEmptyChain(stateInputs, page) {
    if(!stateInputs || !page || typeof page !== 'string') return false;
    let emptChainArr = false;
    for(let i = 0; i < stateInputs[page].chains.length; i++) {
        const currentChain = stateInputs[page].chains[i];
      const emptChain =  currentChain.chainItems.every(i => (typeof i !== 'object'));
      if (emptChain) {
        emptChainArr = [true, currentChain.chainId];
        break;
      };
    }
    return emptChainArr;
}

export function figureToastSettings(figureValueLength) {
    return {
        className: `${figureValueLength ? 'textGreen' : 'textBlue'} md-text`,
        iconTheme: {
            primary: figureValueLength ? appConfiguration.appStyles.secondaryColor : appConfiguration.appStyles.mainColor,
        },
    }
}