import React, { useEffect, useRef, useState } from "react";
import styles from "./home.module.css";
import { useHistory, withRouter } from "react-router-dom";
import NavbarApp from "../../../ui-components/nav-bar"; 
import { addNewDocument, deleteDoc, getUserDocs, removeCookie, setCookie, updateDocFields } from "../../../../firebase/helpers";
import DocumentItem from "../../../ui-components/document-item";
import { setDocumentState } from "../../../../store/inputs-reducer/inputs.actions";
import { initialUserInputsState } from "../../../../store/inputs-reducer/inputs.reducer";
import { useDispatch } from "react-redux";
import "../../../../App.css";
import background_2 from '../../../../assets/background_2.png'
import { ChevronLeft, ChevronRight, Plus } from "react-bootstrap-icons";
import MainLogo from "../../../ui-components/mainLogo";
import QuestFrame from "../../../ui-components/quest-frame";
import CircleButton from "../../../ui-components/circle-button";
import Col from "react-bootstrap/esm/Col";
import Loader from "../../../ui-components/custom-loader";
import fire from 'firebase/app';
import 'firebase/auth';
import { appConfiguration } from "../../../../store/configuration";

export default function Home() {
  const tableContainer = useRef()
  const [tableHeight, setTableHeight] = useState(tableContainer.current && tableContainer.current.offsetHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const [userDocs, setUserDocs] = useState(false);
  const history = useHistory();

  async function createNewDoc() {
    const returnedDocumentID = await addNewDocument();
    dispatch(setDocumentState(initialUserInputsState));
    getDocs().then(() => {
      const docElement = document.getElementById(returnedDocumentID).getElementsByClassName('doc-input-field')[0];
      if(docElement) docElement.click();
    })
  }
  async function duplicateDoc(duplicateState, docName) {
    await addNewDocument(duplicateState, docName).then(getDocs);
  }
  async function removeDoc(docId) {
    if(!window.confirm('מסמך זה ימחק לצמיתות, האם להמשיך ?')) return;
    deleteDoc(docId, getDocs)
  }
  async function getDocs() {
    setUserDocs(false)
    const userDocsArr = await getUserDocs();
    setUserDocs(userDocsArr);
  }
  function openDocument(docData, docId) {
    dispatch(setDocumentState(docData));
    setCookie("currentDocId", docId)
    history.push("/page-1");
  }
  function handleChange({ target }, docId) {
    const { name, value } = target;
    updateDocFields(name, value, docId)
  }
  
  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        getDocs();
        removeCookie(["currentDocId"])
        localStorage.removeItem("_binddata");
        window.onresize = () => {
          userDocs && setTableHeight(tableContainer.current.offsetHeight)
          setWindowWidth(window.innerWidth)
        }
      } else {
          history.push("/sign-in");
      }
    });
    window.onbeforeunload = null;
    window.onload = null;
    return () => {
      window.onresize = null;
    }
  }, []);
  useEffect(() => {
    userDocs && setTableHeight(tableContainer.current.offsetHeight)
  })
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.unOrderedPages.find((page) => page.slug === "home_page").title} />
      <div className={"inner-wrapper"}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={'flex-grow-1'}></div>
            <div className={"attachToBottom "}>
                <MainLogo />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={`main-content`}>
                <QuestFrame frameType={"unorder"} additionalClasses={'h50'} additionalStyles={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
                <Col className={'position-relative'}>
                  {userDocs ? <div className={`${styles.tableWrapper} scrollbar-off contentFade`} ref={tableContainer}>
                      <table className={styles.docTable} style={{ borderCollapse: 'separate', borderSpacing: '3px', tableLayout: 'fixed', width: '100%' , marginBottom: '30px' }}>
                        <thead className={styles.tableHead}>
                          <tr>
                            <th className={'bgBlue text-light md-text'}>שם המסמך</th>
                            <th className={'bgBlue text-light md-text'}>נוצר בתאריך</th>
                            <th className={'bgBlue text-light md-text'}>עודכן</th>
                            {windowWidth > 1200 && <th></th>}
                          </tr>
                        </thead>
                        <tbody className={styles.tableBody} >
                          { userDocs.map((doc) => (
                            <React.Fragment key={doc.docId}>
                              <DocumentItem
                                itemKey={doc.docId}
                                data={doc}
                                action={openDocument}
                                duplicateDoc={duplicateDoc}
                                removeDoc={removeDoc}
                                handleChange={handleChange}
                                getDocs={getDocs}
                                windowWidth={windowWidth}
                              />
                              </React.Fragment>
                          ))}
                        </tbody> 
                      </table>
                    </div> : <Loader /> }
                    {userDocs && <div style={{height: tableHeight}}></div>}
                    {userDocs && <div>
                    <CircleButton
                      action={createNewDoc}
                      buttonColor={'#82BB2D'}
                      iconComponent={<Plus color={'white'} size={32}/>}
                      text={'יצירת מסמך חדש'}
                      additionalClasses={'sm-text'}
                      containerAdditionalStyle={{margin: '8px', fontWeight: 'bold'}} />
                  </div>}
                </Col>
                </QuestFrame>
                <div className={'h40'} style={{ height: 'auto', marginTop: 'auto'}}>
                  <img src={background_2} className={'responsive-img'} alt={''}/>
                </div>
            </div>
          </div>
          <div className={'left-side-strip'}>
            <div className={'flex-grow-1'}></div>
              <div className={"navButtonsWrapper textGreen"}>
                <ChevronRight className={"browseArrows"} onClick={() => history.push("./intro")}/>
                <ChevronLeft className={"browseArrows-inactive"}/>
              </div>
          </div>
        </div>
      </div>
      <div className={'downer-line'}></div>
    </div>
  );
}

Home = withRouter(Home);