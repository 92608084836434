import firebase from 'firebase/app';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyCn-wt-OW9s_IUDwshUr6O8kJo1GdO0j4I",
  authDomain: "regulator-8e1c9.firebaseapp.com",
  databaseURL: "https://regulator-8e1c9.firebaseio.com",
  projectId: "regulator-8e1c9",
  storageBucket: "regulator-8e1c9.appspot.com",
  messagingSenderId: "423697792118",
  appId: "1:423697792118:web:26c45efa5dd8d96f1bb357",
  measurementId: "G-CBTMQ5XMLY"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;