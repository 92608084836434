import * as ERROR_MESSAGES from "./customErrMsg";


export const getFirebaseErrorMessage = code => {
    let message = null;

    switch (code) {
        case "auth/user-not-found":
          message = ERROR_MESSAGES.USER_NOT_FOUND;
          break;
        case "auth/email-already-exists":
          message = ERROR_MESSAGES.EMAIL_ALREADY_EXIST;
          break;
        case "auth/internal-error":
          message = ERROR_MESSAGES.INTERNAL_ERROR;
          break;
        case "auth/weak-password":
          message = ERROR_MESSAGES.WEAK_PASSWORD;
          break;
        case "auth/wrong-password":
          message = ERROR_MESSAGES.WRONG_PASSWORD;
          break;
        case "equal-passwords":
          message = ERROR_MESSAGES.PASSWORD_MUST_EQUAL;
          break;
        case "auth/invalid-email":
          message = ERROR_MESSAGES.INVALID_EMAIL_FORMAT;
          break;
        case "auth/email-already-in-use":
          message = ERROR_MESSAGES.ALREADY_IN_USE;
          break;
        default:
          message = ERROR_MESSAGES.DEFAULT_MESSAGE;
          break;
    }
    return message;
}