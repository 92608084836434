import { createStore, combineReducers } from "redux";
import inputsReducer from "./inputs-reducer/inputs.reducer";
import summaryReducer from "./summary-reducer/summary.reducer";

const mainReducer = combineReducers({
    inputs: inputsReducer,
    summary: summaryReducer
});

const store = createStore(mainReducer);
export default store;