import React from 'react';
import { ChatSquareText } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import ReactTooltip from 'react-tooltip';
import '../../../App.css'

export default function QuerySection(props) {
    const { question, ph, name, value, action, textTooltip = false} = props;
    
    return (
        <div className={'d-flex flex-column font-weight-bold textBlue h-100'}>
                {textTooltip && <ReactTooltip
                id={'question-tp'}
                place={'top'}
                effect={'float'}
                className={'boxShadow font-weight-bold textBlue'}
                backgroundColor={'white'}
                arrowColor={'white'}
                multiline={true}
            />}
            <div>
                <Form.Label className={`p-0 m-0 md-text ${textTooltip && 'cursor-help'}`} data-for={'question-tp'} data-tip={textTooltip ? textTooltip : ''}>
                    {question} {textTooltip && <span className={'textGreen mr-1'}> <ChatSquareText size={18} /></span>}
                </Form.Label>
                <span className={'d-block text-muted sm-text'}>{ph}</span>
            </div>
            <div className={'flex-grow-1'}>
                <textarea className={'rulesTextarea custom-scrollbar text-muted sm-text'} name={name} defaultValue={value} style={{resize: 'none'}} onBlur={(e) => action(e)}></textarea>
            </div>
        </div>
    )
}