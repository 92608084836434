import React from 'react';
import styles from './index.module.css'
import '../../../App.css';
import { appConfiguration } from '../../../store/configuration';
import SummaryItem from '../summary-item';
import { X } from 'react-bootstrap-icons';
import probabilty_icon from '../../../assets/icons/probabilty_icon.png';

export default function SummaryItemsWrapper() {
    const summaryItems = appConfiguration.summaryItems;

    return (
        <div className={`${styles.itemsContainer}`}>
            {summaryItems.map((gridItem, i) => {
                if (gridItem.slug !== 'חומרה') return null;
                return (<div key={gridItem.slug} className={` ${styles.gridItem}`}>
                    <div className={`${styles.iconWrapper}`}>
                        <img src={gridItem.icon.source} alt={gridItem.icon.alt} width={'80%'} height={'auto'}/>
                    </div>
                    <div className={`mr-3 ${styles.gridItemContent}`}>
                        <div className={`attachedText p-0 m-0`}>
                            <SummaryItem itemKey={i} gridItemData={gridItem} textColorClass={'textBlue'} />
                        </div>
                    </div>
                </div>
                )}
            )}
            <div className={'d-flex justify-content-center textGreen px-3'} style={{fontSize: '4vw'}}><X/></div>
            <div className={'d-flex flex-column'}>
                <div className={'d-flex align-items-center justify-content-center'}><img src={probabilty_icon} width={'50px'} height={'auto'}/> <p className={'textBlue font-weight-bold lg-text mr-3'}>הסתברות</p></div>
                <div className={'pt-4'}>
                    {summaryItems.map((gridItem, i) => {
                            if (gridItem.slug !== 'כמות הפגיעות') return null;
                            return (<div key={gridItem.slug} className={`${styles.gridItem} px-4`}>
                                <div className={`mr-2`}>
                                    <img src={gridItem.icon.source} alt={gridItem.icon.alt} width={'75%'} height={'auto'}/>
                                </div>
                                <div className={`mr-3 ${styles.gridItemContent}`}>
                                    <div className={`attachedText p-0 m-0`}>
                                        <SummaryItem itemKey={i} gridItemData={gridItem} textColorClass={'textGray'} />
                                    </div>
                                </div>
                            </div>
                            )}
                        )}
                        <div className={'d-flex align-items-center justify-content-center bgGreen my-3'} style={{width: '100%', height: '4px', borderRadius: '25px'}}></div>
                        {summaryItems.map((gridItem, i) => {
                            if (gridItem.slug !== 'היקף הפעילות') return null;
                            return (<div key={gridItem.slug} className={`${styles.gridItem} px-4`}>
                                <div className={`mr-2`}>
                                    <img src={gridItem.icon.source} alt={gridItem.icon.alt} width={'75%'} height={'auto'}/>
                                </div>
                                <div className={`mr-3 ${styles.gridItemContent}`}>
                                    <div className={`attachedText p-0 m-0`}>
                                        <SummaryItem itemKey={i} gridItemData={gridItem} textColorClass={'textGray'} />
                                    </div>
                                </div>
                            </div>
                            )}
                        )}
                </div>
            </div>
        </div>
    )
}