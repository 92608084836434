import React, { useState, useEffect } from 'react';
import CircleButton from '../circle-button';
import { Check, X } from 'react-bootstrap-icons';
import styles from './descript_cloud_form.module.css';
import '../../../App.css'

export default function DescriptCloudForm(props) {
    const { handleChange, localState, inputModalState, setInputModalState, shortCloud, handleSubmit, abortInfoItemAddition } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.onresize = () => {
            setWindowWidth(window.innerWidth)
          }
    }, [])
    return (
        <div className={`${styles.descriptsCloud} ${styles.descriptsCloudForm} xs-text scrollbar-off`}>
                <div className={`title-tag bgBlue`}>
                    <p className={'m-0 p-0 text-center md-text'}>הוספת פריט מידע</p>
                </div>
                <div className={'flex-grow-1 mb-1'}>
                    <textarea className={'whiteRulesTextarea custom-scrollbar'} name={`descriptText`} style={{resize: 'none'}} onBlur={(e) => handleChange(e)}></textarea>
                </div>
                <div className={`d-flex ${shortCloud && 'flex-column'}`}>
                    <div className={'d-flex flex-column'}>
                        <div className={'customSwitchWrapper flex-grow-1'}>
                            <label className={'customSwitchBtn'}>
                                <input type={'radio'} value={'עובדה אמפירית מגובה במקור'} name={`radioGroup_1`} onChange={(e) => handleChange(e)} checked={localState.radioGroup_1 === 'עובדה אמפירית מגובה במקור'}/>
                                <span className={'round'}></span>
                            </label>
                            <span className={'customSwitchText'}>עובדה אמפירית מגובה <span className={'generalBlueLink textBlue font-weight-bold'} onClick={() => setInputModalState({...inputModalState, show: true} )} size={'sm'} variant={'light'}>במקור</span> *</span>
                        </div>
                        <div className={'customSwitchWrapper flex-grow-1'}>
                            <label className={'customSwitchBtn'}>
                                <input type={'radio'} value={'השערה'} name={`radioGroup_1`} onChange={(e) => handleChange(e)} checked={localState.radioGroup_1 === 'השערה'}/>
                                <span className={'round'}></span>
                            </label>
                            <span className={'customSwitchText'}>השערה</span>
                        </div>
                        <p className={'xxs-text text-muted m-0'}>* ניתן לתעד את המקור בלחיצה על הלינק אבל לא חובה</p>
                    </div>
                    <div className={`flex-grow-1 d-flex justify-content-center ${shortCloud && 'mt-4'}`}>
                        <CircleButton
                            action={handleSubmit}
                            buttonColor={'#82BB2D'}
                            textUnder={false}
                            text={windowWidth > 1400 ? 'הוספת הפריט': ' '}
                            title={'הוספת הפריט'}
                            iconComponent={<Check color={'white'} size={32}/>}
                            containerAdditionalStyle={{ marginLeft: '20px'}}
                            additionalClasses={'xs-text'}/>
                        <CircleButton 
                            action={abortInfoItemAddition}
                            buttonColor={'#e84e0e'}
                            textUnder={false}
                            text={windowWidth > 1400 ? 'ביטול ': ' '}
                            title={'ביטול'}
                            iconComponent={<X color={'white'} size={32}/>}
                            containerAdditionalStyle={{ marginLeft: '10px'}}
                            additionalClasses={'xs-text'}/>
                    </div>
                </div>
        </div>
    )
}