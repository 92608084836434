import React, { useState } from "react";
import fire from '../../../../firebase/index'
import { useHistory } from "react-router-dom";
import { getFirebaseErrorMessage } from "../../../../firebase/commonUtils";
import styles from './signup.module.css'
import logo2 from '../../../../assets/logo2.png';
import '../../../../App.css'
import MainLogo from "../../../ui-components/mainLogo";
import { setCookie } from "../../../../firebase/helpers";
import PillButton from "../../../ui-components/pillButton";

export default function SignUp() {
  const initialLocalState = {userName: '', password: '', rePassword: '', fireErrors: ''}
  const [localState, setLocalState] = useState(initialLocalState)
  const history = useHistory()

  function navigate() {
    history.push('/intro')
  }
  
  function signup(e) {
    e.preventDefault()
    if (localState.password !== localState.rePassword) {
      setLocalState({...localState, fireErrors: getFirebaseErrorMessage('equal-passwords')})
      return;
    }
    fire.auth().createUserWithEmailAndPassword(localState.userName, localState.password).then((response) => {
      setCookie('userId', response.user.uid)
      navigate()
    }).catch(function(error) {
      var errorCode = error.code;
      setLocalState({...localState, fireErrors: getFirebaseErrorMessage(errorCode)})
    });
  }
  
  function updateInputValue(evt, key) {
    setLocalState({
      ...localState, [key]: evt.target.value
    });
  }
  
  return (
    <div className={styles.signupMain}>
      <div className={styles.pageWrapper}>
        <div className={styles.uppearStrip}>
          <div>
            <MainLogo />
          </div>
          <div className={'mr-auto'}>
            <img src={logo2} height={"80px"} alt={'מדיניות רגולציה'}/>
          </div>
        </div>
        <div className={styles.bottomStrip}>
          <div className={styles.pageHeader}>
            <h1>
              <span className={`textBlue font-weight-bold`}> המפתח </span> 
              <span className={`textGreen`}>לזיהוי בעיה</span>
            </h1>
          </div>
          <div className={'justify-self-center flex-grow-1 d-flex align-items-center'}>
            <div className={`${styles.boxWrapper}`}>
                <div className={styles.signupHeader}>
                  <h3>ברוכים <span className={`textBlue font-weight-bold`}>הבאים</span></h3>
                  <div className={styles.lineBottom}></div>
                </div>
                <form className={styles.signupForm}>
                <p className={`${styles.errorsMsg} textBlue`}>{localState.fireErrors}</p>
                  <div className="form-group">
                    <label className={`${styles.formLabel} textBold`}>דואר אלקטרוני</label>
                    <input type="text"
                      onChange={evt => updateInputValue(evt, 'userName')}
                      className={`${styles.formInput}`} placeholder="דואר אלקטרוני" />
                  </div>

                  <div className="form-group">
                    <label className={`${styles.formLabel} textBold`}>סיסמה</label>
                    <input type="password" className={`${styles.formInput}`}
                      onChange={evt => updateInputValue(evt, 'password')}
                      placeholder="רשמי סיסמה" />
                  </div>

                  <div className="form-group">
                    <label className={`${styles.formLabel} textBold`}>אימות סיסמה</label>
                    <input type="password" className={`${styles.formInput}`} onChange={evt => updateInputValue(evt, 'rePassword')} placeholder="אימות סיסמה" />
                  </div>

                  <div className={styles.signupFooter}>
                    <PillButton
                      action={signup}
                      buttonColor={'#82bb2d'}
                      text={'הרשמה'}
                      containerAdditionalStyle={{width: '35%', height: '30px', margin: '0 auto',padding: '0% 10%'}}
                    />
                    <div>
                      <span className={'generalLink'} onClick={() => history.push('./sign-in')}>יש לי משתמש</span>
                    </div>      
                  </div>
                </form>
            </div>
          </div>
          <div className={'downer-line mt-auto'}></div>
        </div>
      </div>
    </div>
  )
}
