import React from 'react';
import { X } from 'react-bootstrap-icons';
import '../../../../App.css';
import vector_7 from '../../../../assets/Vector_7.png';

export default function Diagram () {

    return (
        <div style={{marginBottom: '50px'}}>
            <div className={'text-center mb-5'}><img src={vector_7} style={{borderRadius: '25px'}} width={'450px'} height={'140px'}/></div>
           <div className={'d-flex justify-content-center'} >
                <ul>
                    <li className={'text-muted'}><span className={'textBlue font-weight-bold md-plus-text'}>תיאור הנזק </span><span className={'textGreen font-weight-bold md-plus-text'}> | </span><span className={'text-muted md-text'}>תיאור הפגיעה באינטרס הציבורי (התוצאה) . </span></li>
                    <li className={'text-muted'}><span className={'textBlue font-weight-bold md-plus-text'}>שרשרת פעילות סיבתית </span><span className={'textGreen font-weight-bold md-plus-text'}> | </span><span className={'text-muted md-text'}>תיאור כל שלב בשלבי הפעילות המובילים לנזק (הסיבות) . </span></li>
                    <li className={'text-muted'}>
                        <span className={'textBlue font-weight-bold md-plus-text'}>עוצמת הפגיעה 
                        </span><span className={'textGreen font-weight-bold md-plus-text'}> | </span>
                        <span className={'text-muted md-text'}>
                            השילוב שבין חומרת הנזק (סוג הפגיעה) לבין ההסתברות להתממשותו<br/>
                            (כמות הפגיעות ביחס לכלל הקף הפעילות). להלן תרשים לנוחיותך:
                        </span>
                    </li>
                </ul>
           </div>
           <div className={'d-flex justify-content-center mb-5'}>
                <div className={'text-center'}>
                    <p className={'textBlue font-weight-bold md-plus-text m-0'}> חומרה <span className={'text-muted  md-text'}>(סוג הפגיעה)</span></p>
                    <ul style={{listStyleType: 'circle'}}>
                        <li className={'text-muted md-text'}>גוף, נפש, רכוש</li>
                        <li className={'text-muted md-text'}>הפיכה / בלתי הפיכה</li>
                        <li className={'text-muted md-text'}>מיידית / מצטברת</li>
                    </ul>
                </div>   
                <div className={'textGreen md-text text-center mx-5'}> <X size={75}/> </div>   
                <div>  
                    <p className={'textBlue font-weight-bold md-plus-text text-center m-0'}> הסתברות </p>
                    <p className={'m-0 px-2 py-2 text-muted md-text'} style={{borderBottom: '4px solid #82bb2d'}}>כמות הפגיעות (בזמן נתון) ?</p>
                    <p className={'m-0 px-2 py-2 text-muted md-text'}>היקף הפעילות (בזמן נתון) ?</p>
                </div>   
            </div>
            <div className={'text-muted md-text text-center m-auto'}>
                <p>מוצע להזין למערכת כל אחד מהרכיבים וחלקיו בנפרד. למשל,<br/>
                    בתיאור ההסתברות לפגיעה נתאר את המונה (כמות הפגיעות) בנפרד מהמכנה (הקף הפעילות).<br/>
                    הזנה כזו תאפשר לך בהמשך העבודה במערכת להבנות ולייצר תרחישים שונים של שרשראות ונזקים.
                </p>
            </div>
        </div>
    )
}