import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import '../../../App.css'

export default function PillButton(props) {
    const { action, buttonColor, text, containerAdditionalStyle, iconComponent, additionalClasses, tooltipData } = props
    const [isHovered, setIsHovered] = useState(false)

    const buttonStyle = {
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center',
            height: '25px',
            backgroundColor: buttonColor,
            color: "white",
            padding: '0 3%',
            border: 'none',
            borderRadius: '25px',
            fontWeight: 'bold',
            cursor: 'pointer',
            fontFamily: "Rubik",
            filter: isHovered && 'brightness(110%)',
            transition: 'all .2s',
            ...containerAdditionalStyle
        },
    }

    return (
        <>
            {tooltipData && <ReactTooltip
                id={'pillbutton-tp'}
                place={'top'}
                effect={'float'}
                className={'boxShadow font-weight-bold textRed'}
                backgroundColor={'white'}
                arrowColor={'white'}
                multiline={true}
            />}
            <button data-for={'pillbutton-tp'} data-tip={tooltipData} className={`${additionalClasses ? additionalClasses: ''}`} style={{...buttonStyle.buttonContainer}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={(e) => action(e)} title={tooltipData ? "" : text}>
                {iconComponent ? <><span className={'ml-2'}>{iconComponent}</span> <span>{text}</span></> : <span>{text}</span> } 
            </button>
        </>
    )
}
