import React, { useState, useEffect, useRef } from "react";
import FormControl from "react-bootstrap/esm/FormControl";
import { Dash, ExclamationTriangle, Files, Plus, X } from "react-bootstrap-icons";
import ChainItem from "../chain-item";
import { addChainItem, removeChain, unClassifyChain, updateChain, duplicateChain, updatePage_3 } from "../../../store/inputs-reducer/inputs.actions";
import { useSelector, useDispatch } from "react-redux";
import styles from "./chain.module.css";
import CircleButton from "../circle-button";
import InputModal from "../input-modal";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast from "react-hot-toast";
import { figureToastSettings } from "../../containers/pages/quest-pages/helpers";
import { appConfiguration } from "../../../store/configuration";
import PillButton from "../pillButton";

export default function Chain(props) {
  const { action, showOverlay, checkEmptyChains, scrollTo, chainId, domID, chainName, chainItems, frequency_scope_item, frequency_amount_item, severity_item, page, itemKey } = props;
  const stateInputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const modalInitialValues = { frequency_scope_item: frequency_scope_item, frequency_amount_item: frequency_amount_item, severity_item: severity_item, show: false }
  const [chainNameChangeAction, setChainNameChangeAction] = useState(false);
  const [inputModalState, setInputModalState] = useState(modalInitialValues)
  const chainsItemEndRef = useRef();

  function checkRelevanceAction() {
    const { items: seveItems } = stateInputs.page_4.containers.find(container => container.slug === "חומרה");
    const { items: freqScopeItems } = stateInputs.page_4.containers.find(container => container.slug === "היקף הפעילות");
    const { items: freqAmountItems } = stateInputs.page_4.containers.find(container => container.slug === "כמות הפגיעות");
    if (!seveItems.length) _unClassify("severity_item") ;
    if (!freqScopeItems.length) _unClassify("frequency_scope_item");
    if (!freqAmountItems.length) _unClassify("frequency_amount_item");

    if (severity_item) {
      const isRelevant = seveItems.some(item => item.infoItemId === severity_item);
      if (!isRelevant) _unClassify("severity_item");
    }
    if (frequency_scope_item) {
      const isRelevant = freqScopeItems.some(item => item.infoItemId === frequency_scope_item);
      if (!isRelevant)  _unClassify("frequency_scope_item");
    }
    if (frequency_amount_item) {
      const isRelevant = freqAmountItems.some(item => item.infoItemId === frequency_amount_item);
      if (!isRelevant)  _unClassify("frequency_amount_item");
    }

    function _unClassify(classification) {
      dispatch(unClassifyChain({ chainId, classification, page }));
    }
  }
  function handleModalSubmit(changeAction) {
    let itemIndex = null;
    if (inputModalState.severity_item !== severity_item) {
      _updateChainAction("severity_item")
      _toastAction('chainClass_severity_item_modified')
    }
    if (inputModalState.frequency_scope_item !== frequency_scope_item) {
      _updateChainAction("frequency_scope_item")
      _toastAction('chainClass_frequency_scope_modified')
    }
    if (inputModalState.frequency_amount_item !== frequency_amount_item) {
      _updateChainAction("frequency_amount_item")
      _toastAction('chainClass_frequency_amount_modified')
    }
    
    setInputModalState({...inputModalState, show: false})
    function _updateChainAction(name) {
      const target = { name: name, value: inputModalState[name]}
      dispatch(updateChain({ chainId, target, itemIndex, changeAction, page }));
    }
    function _toastAction(toastKey) {
      const toastMessage = appConfiguration.toastMessages[toastKey];
      toast.success(toastMessage, figureToastSettings(1))
    }
    
  }
  function handleModalSelect({ name, value }) {
    setInputModalState({...inputModalState, [name]: value})
  }
  function handleModalAbort() {
    if (inputModalState.frequency_amount_item !== frequency_amount_item || inputModalState.frequency_scope_item !== frequency_scope_item || inputModalState.severity_item !== severity_item) {
      const toastMessage = appConfiguration.toastMessages.chainClassAbort;
      toast.success(toastMessage, figureToastSettings(0))
    }
    setInputModalState(modalInitialValues)
  }
  function handleChange({ target }, itemIndex, changeAction, infoItemId) {
    dispatch(updateChain({ chainId, target, itemIndex, changeAction, page }));
    dispatch( updatePage_3({ payloadValues: { name: 'radioGroup_1', value: target.value }, infoItemId: infoItemId, }));
  }

  function addChainItemAction() {
    dispatch(addChainItem({ chainId, page }));
    setTimeout(() => {
      chainsItemEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }
  function removeChainAction() {
    confirmAlert({
      overlayClassName: 'confirm-overlay',
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h5 className={'textBlue'}>שרשרת זו תימחק לצמיתות, האם להמשיך ?</h5>
            <p className={'textRed sm-text text-center m-0 mb-3 p-0'}><ExclamationTriangle size={18}/> לא ניתן לשחזר שרשרת שנמחקה </p>
            <div className={'d-flex justify-content-center'}>
              <PillButton
                buttonColor={'#82bb2d'} 
                text={'אישור'} 
                containerAdditionalStyle={{ marginLeft: '10px' }} 
                action={() => {
                  dispatch(removeChain({ chainId, page }));
                  onClose();
                }}/>
              <PillButton
                buttonColor={'#e84e0e'} 
                text={'ביטול'} 
                containerAdditionalStyle={{ }} 
                action={() => onClose()}/>
            </div>
              <p className={'xs-text text-muted m-0 mt-4 p-0'}>* פריטי המידע שנמצאים בשרשרת יועברו לענן מצד שמאל</p>
          </div>
        );
      }
    });
  }
  function duplicateChainAction() {
    if (!chainItems.every(i => (typeof i !== 'object'))) {
      _startDup()
    } else if (checkEmptyChains(true)) {
      _startDup()
    }
    function _startDup() {
      dispatch(duplicateChain({ chainId, page }));
      scrollTo(true);
    }
  }

  useEffect(() => {
    if (severity_item || frequency_scope_item || frequency_amount_item) checkRelevanceAction();
  }, []);
  return (
    <OverlayTrigger 
      overlay={ 
      <Tooltip className={`${styles.overlayTooltip} xs-text`} style={{ opacity: "1" }}> 
        <span className={`${styles.tooltipIcon} textRed`}><ExclamationTriangle size={16}/></span>
        <p className={'font-weight-bold text-center p-0 m-0 textRed'}>שימי לב</p> 
        <p className={'font-weight-bold p-0 m-0 textRed'}> תחילה יש למלא שרשרת ריקה זו</p> 
        <span className={'xxs-text text-muted'}>* לפחות פריט 1</span>
      </Tooltip> }
      show={showOverlay[1] === chainId ? showOverlay[0] : false} placement={"top"} className={'textRed'}> 
      <div id={domID} className={`${styles.chainWrapper}`} key={itemKey}>
          <InputModal
            onHide={handleModalAbort}
            show={inputModalState.show}
            modalTitle={<div className={'md-text p-0 m-0 font-weight-bold text-center'}>קבעי את עוצמת הפגיעה</div>}
            handleSubmit={() => handleModalSubmit('setClassification')}
            onAbort={handleModalAbort}>
            <div className={`${styles.chainClassifyModalContent}`}>
              <div className={`${styles.chainClassifyModalItems} my-1`}>
                <span className={`font-weight-bold xl-text textBlue text-center py-3`}>חומרה</span>
                <select className={`thick-square-custom-scrollbar textGray`} defaultValue={inputModalState.severity_item ? inputModalState.severity_item : "NOVALUE"} name={"severity_item"} onChange={(e) => handleModalSelect(e.target)}>
                  <option value={"NOVALUE"} className={'md-text'}> לחצי כאן לביטול הבחירה או בחרי חומרה </option>
                  {stateInputs.page_4.containers.map((container) =>  {
                    if(container.slug === 'חומרה') {
                      return container.items.map((item) => {
                        return <option key={item.infoItemId} className={'sm-text muted-text'} value={item.infoItemId} title={item.descriptText}> {item.descriptText && item.descriptText.length > 60 ? item.descriptText.substr(0,60) + '...' : item.descriptText} </option>
                      })
                    }
                  })}
                </select>
              </div>
              <div className={'flex-grow-1 d-flex textGreen'} style={{fontSize: '4vw'}}><X/></div>
              <div className={"d-flex flex-column w-100 py-3"}>
                <p className={'text-center textBlue font-weight-bold xl-text  m-0'}>הסתברות</p>
                <div className={`${styles.chainClassifyModalItems} px-3`}>
                  <span className={`font-weight-bold md-text text-center textGray`}>כמות הפגיעות</span>
                  <select className={`thick-square-custom-scrollbar textGray`} defaultValue={inputModalState.frequency_amount_item ? inputModalState.frequency_amount_item : "NOVALUE"} name={"frequency_amount_item"} onChange={(e) => handleModalSelect(e.target)}>
                    <option value={"NOVALUE"} className={'md-text'}> לחצי כאן לביטול הבחירה או בחרי כמות הפגיעות </option>
                    {stateInputs.page_4.containers.map((container) => {
                        if(container.slug === 'כמות הפגיעות') {
                          return container.items.map((item) => {
                            return <option key={item.infoItemId} className={'sm-text muted-text'} value={item.infoItemId} title={item.descriptText}> {item.descriptText && item.descriptText.length > 60 ? item.descriptText.substr(0,60) + '...' : item.descriptText} </option>
                          })
                        }
                    })}
                  </select>
                </div>
                <div className={'d-flex align-items-center justify-content-center bgGreen my-3'} style={{width: '100%', height: '4px', borderRadius: '25px'}}></div>
                <div className={`${styles.chainClassifyModalItems} px-3`}>
                  <span className={`font-weight-bold md-text text-center textGray`}>היקף הפעילות</span>
                  <select className={`thick-square-custom-scrollbar textGray`} defaultValue={inputModalState.frequency_scope_item ? inputModalState.frequency_scope_item : "NOVALUE"} name={"frequency_scope_item"} onChange={(e) => handleModalSelect(e.target)}>
                    <option value={"NOVALUE"} className={'md-text'}> לחצי כאן לביטול הבחירה או בחרי היקף הפעילות </option>
                    {stateInputs.page_4.containers.map((container) => {
                        if(container.slug === 'היקף הפעילות') {
                          return container.items.map((item) => {
                            return <option key={item.infoItemId} className={'sm-text muted-text'} value={item.infoItemId} title={item.descriptText}> {item.descriptText && item.descriptText.length > 60 ? item.descriptText.substr(0,60) + '...' : item.descriptText} </option>
                          })
                        }
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
          </InputModal>
        <div className={`text-muted w-100 text-center ${styles.chainHeader}`}>
          {chainNameChangeAction ? (
            <div className={"d-flex align-items-center mb-2"}>
              <FormControl
                size={"sm"}
                className={"bg-transparent text-center outlineOff"}
                name={"chainName"}
                onChange={(e) => handleChange(e, null, "setChainName")}
                onBlur={() => setChainNameChangeAction(false)}
                defaultValue={chainName}
                autoComplete={"off"}
                autoFocus={true}
                placeholder={"הקלידי שם לשרשרת"}
              />
            </div>
          ) : (
            <div className={'sm-text mb-2'} onClick={() => setChainNameChangeAction(true)}>
              {chainName ? <p className={'m-0 p-0'}>{chainName}</p> : <p className={'m-0 p-0 py-1'}>הקלידי שם לשרשרת</p>}
            </div>
          )}
        </div>
        <div className={`${styles.chainItemsWrapper} scrollbar-off`}>
          {chainItems.map((item, i) => (
            <ChainItem
              key={i}
              action={action}
              handleChange={handleChange}
              itemIndex={i}
              chainId={chainId}
              data={item}
            />
          ))}
          <div ref={chainsItemEndRef}></div>
        </div>
        <button className={`${styles.chainClassifyBtn} bgBlue`} onClick={() => setInputModalState({...inputModalState, show: true})}>
          <span className={"font-weight-bold sm-text"}> עוצמת הפגיעה <br/> <span className={"font-weight-normal xs-text"}>{`(הסתברות X חומרה)`}</span> </span>
        </button>
        <div className={`d-flex mt-2 ${styles.chainActions}`}>
          <CircleButton
            action={addChainItemAction}
            buttonColor={"#82BB2D"}
            textUnder={true}
            text={<>הוספת<br />גורם</>}
            title={'הוספת גורם'}
            iconComponent={<Plus color={"white"} size={22} />}
            triggerBooleanState={false}
            additionalClasses={'xs-text'}
            containerAdditionalStyle={{marginLeft: 'auto'}}
            textAdditionalStyle={{ marginTop: "5px" }}
          />

          <CircleButton
            action={duplicateChainAction}
            buttonColor={"#82BB2D"}
            textUnder={true}
            text={<>שכפול<br />שרשרת</>}
            title={'שכפול שרשרת'}
            iconComponent={<Files color={"white"} size={16} />}
            triggerBooleanState={false}
            additionalClasses={'xs-text'}
            textAdditionalStyle={{ marginTop: "5px" }}
          />
          <CircleButton
            action={removeChainAction}
            buttonColor={"#e84e0e"}
            textUnder={true}
            text={<>מחיקת<br />שרשרת</>}
            title={'מחיקת שרשרת'}
            iconComponent={<Dash color={"white"} size={22} />}
            triggerBooleanState={false}
            additionalClasses={'xs-text'}
            containerAdditionalStyle={{marginRight: 'auto'}}
            textAdditionalStyle={{ marginTop: "5px" }}
          />
        </div>
      </div>
    </OverlayTrigger>
  );
}
