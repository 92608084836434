import React from 'react';
import '../../../../App.css';

export default function QuestionBank () {

    return (
        <div>
            <p className={'font-weight-bold md-plus-text textGray'}>בעת הרכבת השרשרת הסיבתית כדאי לחשוב על:</p>
            <ol>
            <li className={`font-weight-bold md-plus-text textBlue`}>הגורמים:
                <ul style={{listStyleType: 'hebrew'}} className={'font-weight-normal md-text textGray'}>
                <li>
                    מי הגורמים המעורבים ?<br/>
                    חשבו לא רק על הגורמים הפוגעים או הנפגעים אלא גם על גורמים ניטרליים בתהליך.<br/>
                    לעיתים לפעולה שלהם יש השפעה על התנהגות הגורמים שנרצה לשנות.
                </li>
                <li className={'mt-1'}>כיצד כל גורם מושפע מפעולות גורם אחר ?</li>
                </ul>
            </li>
            <li className={'font-weight-bold mt-3 md-plus-text textBlue'}>רצף האירועים:
                <ul style={{listStyleType: 'hebrew'}} className={'font-weight-normal md-text textGray'}>
                <li>
                    היכן מתרחש הנזק ?<br/>
                    למשל, בבתי סבים וסבתות ולא בבתי ההורים או באזור גיאוגרפי מסוים בלבד. 
                </li>
                <li className={'mt-1'}>מתי מתרחש הנזק ?</li>
                <li className={'mt-1'}>
                    הסתברות ההתרחשות של הנזק בכל אחד מהשלבים.<br/>
                    למשל, בשלב א' הכשל שמוביל לשלב ב' מתרחש בהסתברות גבוהה, אולם הכשל בשלב ב' שמוביל לנזק – מתרחש בהסתברות נמוכה. 
                </li>
                </ul>
            </li>
            <li className={'font-weight-bold mt-3 md-plus-text textBlue'}>יחסים בין הגורמים:
                <ul style={{listStyleType: 'hebrew'}} className={'font-weight-normal md-text textGray'}>
                <li>מה התמריץ להתנהגות (הרצויה והלא רצויה) כל גורם כפי שהוא נוהג ?</li>
                <li className={'mt-1'}>מה הדינמיקה בין השחקנים ?</li>
                <li className={'mt-1'}>האם ישנו פער בין השחקנים? בעיסוק, בגודל, ביכולות וכו'.</li>
                </ul>
            </li>
            <li className={'font-weight-bold mt-3 md-plus-text textBlue'}>רגולציה קיימת:
                <ul style={{listStyleType: 'hebrew'}} className={'font-weight-normal md-text textGray'}>
                <li>מה הרגולציה הנוכחית שבאחריותך שחלה על הגורמים ?</li>
                <li className={'mt-1'}>מה הרגולציה הנוכחית שאינה באחריותך אבל חלה ומשפיעה על התנהגות הגורמים ?</li>
                </ul>
            </li>
            </ol>
        </div>
    )
}