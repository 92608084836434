import React, { useEffect, useState } from 'react';
import styles from './descript.module.css'
import ReactTooltip from 'react-tooltip';
import { appConfiguration } from '../../../store/configuration';
import { ChatSquareText } from 'react-bootstrap-icons';

export default function DescriptContainer(props) {
    const { items, name, slug, action } = props
    const [ tipContent, setTipContent] = useState('')

    function relevantTipContent() {
        appConfiguration.page_4PopupsContent.find((container) => {
            if(container.slug === slug) {
                setTipContent(container.content)
            }
        })
    }
    function dragStartHandler(e, item) {
        e.dataTransfer.setData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain', JSON.stringify(item))
        ReactTooltip.hide()
    }
    function dropHandler(e) {
        e.preventDefault()
        const item = e.dataTransfer.getData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain')
        action(JSON.parse(item), slug)
    }
    function dragOverHandler(e) {
        e.preventDefault()
    }

    useEffect(() => {
        relevantTipContent()
    }, [])
    return (
        <div className={'d-flex flex-column h-100'}>
        <ReactTooltip 
            id="descriptContainerTip"
            place={'left'}
            className={'textBlue font-weight-bold w-25'}
            border={true}
            borderColor={'#D8D8D8'}
            backgroundColor={'white'}
            arrowColor={'white'}
            effect={'float'}
            multiline={true}
            html={true}/>
        
        <p draggable={false} data-tip={tipContent} data-for={"descriptContainerTip"} className={`text-center m-0 p-0`} style={{cursor: tipContent.length ? 'help' : 'initial'}}>{name} {tipContent.length ? <span className={'textGreen mr-1'}> <ChatSquareText size={16} /></span> : null}</p>
        <div className={`${styles.descripWrapper}`}
        onDrop={(e) => dropHandler(e)}
        onDragOver={(e) => dragOverHandler(e)}
        >
            <div className={`${styles.descriptContainer} custom-scrollbar`}>
            {items.length ? items.map((item) => {
                return <DescriptContainerItem 
                    key={item.infoItemId}
                    data={item}
                    action={dragStartHandler}
                    draggable
                    />
            }) : <span className={`${slug === 'חומרה' ? 'sm-text' :'xs-text'} text-muted font-weight-normal text-wrap`}>גרור פריט לכאן</span>}
            </div>
        </div>
        </div>
    )
}

function DescriptContainerItem(props) {
    const { data , action } = props
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div data-for={data.infoItemId} data-tip={data.descriptText.length ? `<b>תיאור פריט מידע ${data.infoItemIndex}:</b> </br> ${data.descriptText}` : '<b>לא נרשם תיאור</b>'} >
            <div 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onDragStart={(e) => action(e, data)} 
            className={`mr-1 ${isHovered ? 'bgGreen' : 'bgBlue'} ${styles.descriptContainerItem}`}
            draggable
            
            ></div>
            <ReactTooltip 
            id={data.infoItemId}
            place={'bottom'}
            className={'text-muted baseColorToolTip'}
            effect={'solid'}
            html={true}
            multiline={true}/>
        </div>
    )
}