import { ACTIONS } from "../actions.config";

export function findMissingInformationItems(payload) {
    return {
      type: ACTIONS.FIND_MISSING_INFORMATION_ITEMS,
      payload,
    };
}
export function getInfoItemsInPercentage(payload) {
    return {
      type: ACTIONS.GET_INFO_ITEMS_IN_PERCENTAGE,
      payload,
    };
}
export function checkMainClassifications(payload) {
    return {
      type: ACTIONS.CHECK_MAIN_CLASSIFICATIONS,
      payload,
    };
}
export function initialSummaryStateAction() {
    return {
      type: ACTIONS.INITIAL_SUMMARY_STATE,
    };
}