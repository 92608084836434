import React from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.css';
import '../../../App.css';
import { ArrowDown } from 'react-bootstrap-icons';

export default function HiddenChain(props) {
    const { chainId, chainName, chainItems, frequency_scope_item, frequency_amount_item, severity_item , itemref} = props;
    const stateInputs = useSelector((state) => state.inputs);
  
    
    return ( 
        
        <div ref={itemref} id={`HC_${chainId}`} className={`${styles.hiddenChainWrapper}`}>
            <div className={'md-text textBlue text-center font-weight-bold border-bottom border-secondary pb-2 mb-1'}>{chainName.length ? <p>{chainName}</p> : 'לא נבחר שם לשרשרת'}</div>
            <div className={`${styles.hiddenChainItems} sm-text border-bottom border-secondary mb-5`}>
                {chainItems.map((item, i) => {
                    return (
                        item.descriptText ? (<div key={i} className={`${styles.hiddenChainItemWrapper} `}>
                            <div className={`${styles.hiddenChainItem} textBlue text-center`}> <ArrowDown size={36} /> </div>
                            <div className={`${styles.hiddenChainItemContainer}`}>
                                { item.descriptText.length > 280 ? item.descriptText.substr(0,280) + ' ... ' : item.descriptText }
                            </div>
                        </div>) : null
                    )
                })}
            </div>
            <div className={'md-text textBlue text-center font-weight-bold'}><p>עוצמת הפגיעה <span className={'d-block xs-text text-muted font-weight-normal'}>(חומרה X הסתברות)</span></p></div>
            <div>
                <ul className={`${styles.hiddenChainUL} sm-text p-0 m-0 text-center`} style={{listStyleType: 'none'}}>
                    <li className={`text-secondary font-weight-bold mb-3`}>פריט חומרה: 
                    <p className={`text-secondary font-weight-normal`}>{severity_item ? stateInputs.page_4.containers.find((container) => container.slug === 'חומרה').items
                    .find(infoItem => infoItem.infoItemId === severity_item ).descriptText : 'לא נבחר פריט חומרה'}</p>
                    </li>
                    <li className={`text-secondary font-weight-bold mb-3`}>כמות הפגיעות:
                    <p className={`text-muted font-weight-normal`}>{frequency_amount_item ? stateInputs.page_4.containers.find((container) => container.slug === 'כמות הפגיעות').items
                    .find(infoItem => infoItem.infoItemId === frequency_amount_item ).descriptText : 'לא נבחר פריט כמות הפגיעות'}</p>
                    </li>
                    <li className={`text-secondary font-weight-bold mb-3`}>היקף הפעילות:
                    <p className={`text-muted font-weight-normal`}>{frequency_scope_item ? stateInputs.page_4.containers.find((container) => container.slug === 'היקף הפעילות').items
                    .find(infoItem => infoItem.infoItemId === frequency_scope_item ).descriptText : 'לא נבחר פריט היקף הפעילות'}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}