import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/esm/FormControl';
import styles from './info_item.module.css';
import InputModal from '../input-modal';
import '../../../App.css'
import { ChatSquareText, X } from 'react-bootstrap-icons';
import CircleButton from '../circle-button';
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from 'react-bootstrap/esm/Tooltip';

export default function InfoItem(props) {
    const { action, removeInfoItemAction, data } = props
    const { infoItemId , infoItemIndex} = data
    const [modalShow, setModalShow] = useState(false)
    const [modalValue, setModalValue] = useState(data.factSource ? data.factSource : '')
    const currentValues = {
        descriptText: data.descriptText ? data.descriptText : '',
        radioGroup_1: data.radioGroup_1 ? data.radioGroup_1 : false,
        radioGroup_2: data.radioGroup_2 ? data.radioGroup_2 : false,
    }

    function handleAbort() {
        if(modalValue !== data.factSource) {
            setModalValue(data.factSource)
        }
        setModalShow(false)
    }
    function handleChange({ target }) {
        const { value } = target;
        setModalValue(value)
    }
    function handleSubmit() {
        if(modalValue === data.factSource || (!data.factSource && !modalValue.length)) return setModalShow(false);
        action({name: 'factSource', value: modalValue}, infoItemId, infoItemIndex)
        setModalShow(false)
    }
    useEffect(() => {
        setModalValue(data.factSource ? data.factSource : '')
    }, [data])
    return (
        <div key={infoItemId}>
            <InputModal
                handleSubmit={handleSubmit}
                onAbort={handleAbort}
                onHide={handleAbort}
                show={modalShow}
                modalTitle={'הוספת מקור העובדה'}>
                <FormControl className={`modalInput outlineOff sm-text`} defaultValue={modalValue} name={'factSource'} onChange={(e) => handleChange(e)} placeholder={'הקלידי לינק או טקסט חופשי כאן'} autoComplete={'off'}/>
            </InputModal>
            <Form>
                <div className={`${styles.infoItemWrapper} fade-in`}>
                    <div className={styles.removeBtnWrapper}>
                        <CircleButton buttonColor={'#82BB2D'} iconComponent={<X size={20} />} title={'מחיקת פריט מידע'} iconCircleAdditionalStyle={{width: 'fit-content', height: 'fit-content', boxShadow: 'none'}} action={() => removeInfoItemAction(infoItemId, infoItemIndex)}/>
                    </div>
                    <div>
                        <textarea className={'rulesTextarea custom-scrollbar md-text'} defaultValue={currentValues.descriptText} name={`descriptText`} style={{resize: 'none', width: '98%', flex: '1 0 auto'}} placeholder={'הקלידי את המידע'} 
                        onBlur={(e) => {
                            if (e.target.value === currentValues.descriptText) return;
                            action(e.target, infoItemId)
                        }}></textarea>
                    </div>
                    <div className={`${styles.radioWrapper} sm-text`}>
                        <div className={styles.radioGroup_A}>
                            <div className={'customSwitchWrapper'}>
                                <label className={'customSwitchBtn'}>
                                    <input type={'radio'} value={'עובדה אמפירית מגובה במקור'} checked={currentValues.radioGroup_1 === 'עובדה אמפירית מגובה במקור'} name={`radioGroup_1`} onChange={(e) => action(e.target, infoItemId)}/>
                                    <span className={'round'}></span>
                                </label>
                                <span className={'customSwitchText'}>עובדה אמפירית מגובה <span className={'generalBlueLink textBlue font-weight-bold'} onClick={() => setModalShow(true)} size={'sm'} variant={'light'}>במקור</span> *</span>
                            </div>
                            <div className={'customSwitchWrapper'}>
                                <label className={'customSwitchBtn'}>
                                    <input type={'radio'}  value={'השערה'} checked={currentValues.radioGroup_1 === 'השערה'} name={`radioGroup_1`} onChange={(e) => {action(e.target, infoItemId); setModalValue('')}}/>
                                    <span className={'round'}></span>
                                </label>
                                <span className={'customSwitchText'}>השערה</span>
                            </div>
                        </div>
                        <div className={styles.radioGroup_B}>
                            <label className={'customRadioBtn'}>
                                <input type={'radio'} value={'סיבה לבעיה'} checked={currentValues.radioGroup_2 === 'סיבה לבעיה'} name={`radioGroup_2`} onChange={(e) => action(e.target, infoItemId)}/>
                                <div className={'circle'}></div>
                                <OverlayTrigger placement={'left'} trigger={["hover","focus"]} overlay={ <Tooltip id="tooltip"> <span className={'textBlue'}>מיקום/גורמים שונים/תנאי סביבה</span> </Tooltip> }>
                                        <span>סיבה לבעיה <span className={'textGreen mr-1'}> <ChatSquareText size={16} /></span></span>
                                </OverlayTrigger>
                            </label>
                            <label className={'customRadioBtn'}>
                                <input type={'radio'} value={'תיאור הנזק'} checked={currentValues.radioGroup_2 === 'תיאור הנזק'} name={`radioGroup_2`} onChange={(e) => action(e.target, infoItemId)}/>
                                <div className={'circle'}></div>
                                <OverlayTrigger placement={'left'} trigger={["hover","focus"]} overlay={ <Tooltip id="tooltip_1"> <span className={'textBlue'}>פגיעת גוף/רכוש/נפש, נזק הפיך/לא הפיך, פגיעה מיידית ו/או מצטברת</span> </Tooltip> }>
                                        <span>תיאור הנזק <span className={'textGreen mr-1'}> <ChatSquareText size={16} /></span></span>
                                </OverlayTrigger>
                            </label>
                            <label className={'customRadioBtn'}>
                                <input type={'radio'} value={'לא יודעת כרגע'} checked={currentValues.radioGroup_2 === 'לא יודעת כרגע'} name={`radioGroup_2`} onChange={(e) => action(e.target, infoItemId)}/>
                                <div className={'circle'}></div>
                                לא יודעת כרגע
                            </label>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}