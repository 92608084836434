import React, { useState } from 'react';
import '../../../App.css'

export default function CircleButton(props) {
    const { action, additionalArguments, buttonColor, textUnder, title, text, iconComponent, containerAdditionalStyle, iconCircleAdditionalStyle, textAdditionalStyle, triggerBooleanState, additionalClasses } = props
    const [isHovered, setIsHovered] = useState(false)

    const buttonStyle = {
        plusContainer: {
            display: 'inline-flex',
            flexDirection: textUnder ? 'column' : 'row',
            alignItems: 'center',
            userSelect: 'none',
            fontFamily: "Rubik",
            cursor: 'pointer',
            ...containerAdditionalStyle
        },
        iconCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center',
            width: '25px',
            height: '25px',
            marginLeft: textUnder ? '0px' : '10px',
            borderRadius: '50%',
            color: 'white',
            backgroundColor: buttonColor,
            filter: isHovered && 'brightness(115%)',
            boxShadow: `1px 2px 6px ${buttonColor}`,
            transition: 'all .2s',
            ...iconCircleAdditionalStyle
        },
        iconText: {
            color: buttonColor,
            textAlign: 'center',
            lineHeight: '12px',
            filter: isHovered && 'brightness(115%)',
            ...textAdditionalStyle
        }
    }

    return (
        <div title={title} onClick={() => triggerBooleanState ? action(true) : action(additionalArguments)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className={additionalClasses} style={{...buttonStyle.plusContainer}} title={title}>
              <span style={{...buttonStyle.iconCircle}} >
                {iconComponent}
              </span>
              {text && <span style={{...buttonStyle.iconText}}>{text}</span>}
        </div>
    )
}
