import React, { useEffect, useState } from "react";
import styles from "./page_3.module.css";
import { useHistory } from "react-router-dom";
import NavbarApp from "../../../../ui-components/nav-bar";
import vector_3 from "../../../../../assets/Vector_3.png";
import QuestProgress from "../../../../ui-components/quest-progress";
import { ChevronLeft, ChevronRight, ExclamationTriangle, Plus } from "react-bootstrap-icons";
import MainLogo from "../../../../ui-components/mainLogo";
import "../../../../../App.css";
import QuestFrame from "../../../../ui-components/quest-frame";
import { addInfoItem, removeInfoItem, setActiveInfoItem, updatePage_3 } from "../../../../../store/inputs-reducer/inputs.actions";
import { useSelector, useDispatch } from "react-redux";
import CircleButton from '../../../../ui-components/circle-button'
import { getCookie } from "../../../../../firebase/helpers";
import { appConfiguration } from "../../../../../store/configuration";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import { figureToastSettings } from "../helpers";
import PillButton from "../../../../ui-components/pillButton";
import InfoItem from "../../../../ui-components/info-item";
import InfoModal from "../../../../ui-components/modal";
import Diagram from "../../../visual-containers/diagram";

export default function QuestPage3() {
  const stateInputs = useSelector(state => state.inputs)
  const dispatch = useDispatch()
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  
  function handleSubmit({ name, value }, infoItemId, infoItemIndex) {
    if(name === 'factSource') {
      const toastMessage = value.length ? appConfiguration.toastMessages.factAdded + infoItemIndex : appConfiguration.toastMessages.factRemove
      toast.success(toastMessage, figureToastSettings(value.length));
      dispatch( updatePage_3({ payloadValues: { name: 'radioGroup_1', value: 'עובדה אמפירית מגובה במקור' }, infoItemId: infoItemId, }));
      dispatch( updatePage_3({ payloadValues: { name: 'factSource', value: value }, infoItemId: infoItemId, }));
    } else dispatch( updatePage_3({ payloadValues: { name: name, value: value }, infoItemId: infoItemId, }));
  }
  function addInfoItemAction() {
    const newInfoItemId = Date.now() + (Math.random() * 100000).toFixed();
    dispatch( addInfoItem({ infoItemId: newInfoItemId, page: 'page_3', addedFromCloud: false, infoItemData: null }) );
  }
  function removeInfoItemAction(infoItemId, infoItemIndex) {
    confirmAlert({
      overlayClassName: 'confirm-overlay',
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h5 className={'textBlue'}>פריט מידע זה ימחק לצמיתות, האם להמשיך ?</h5>
            <p className={'textRed sm-text text-center'}><ExclamationTriangle size={18}/> לא ניתן לשחזר פריט מידע שנמחק </p>
            <div className={'d-flex justify-content-center'}>
              <PillButton
                buttonColor={'#82bb2d'} 
                text={'אישור'} 
                containerAdditionalStyle={{ marginLeft: '10px' }} 
                action={() => {
                  dispatch(removeInfoItem({infoItemId, infoItemIndex}));
                  onClose();
                }}/>
              <PillButton
                buttonColor={'#e84e0e'} 
                text={'ביטול'} 
                action={() => onClose()}/>
            </div>
          </div>
        );
      }
    });
  }
  function browseInfoItems(action) {
    const currentInfoItemIndex = stateInputs.page_3.activeInfoItem.infoItemIndex;
    switch(action) {
      case 'START': { 
        dispatch(setActiveInfoItem(stateInputs.page_3.infoItemsArr[0])) 
      } break; 
      case 'PREV': { 
        if (currentInfoItemIndex === 1) return;
        dispatch(setActiveInfoItem(stateInputs.page_3.infoItemsArr.filter(infoItem => infoItem.infoItemIndex === (currentInfoItemIndex - 1))[0])) 
      } break;
      case 'NEXT': {
        if (currentInfoItemIndex === stateInputs.page_3.infoItemsArr.length) return;
        dispatch(setActiveInfoItem(stateInputs.page_3.infoItemsArr.filter(infoItem => infoItem.infoItemIndex === (currentInfoItemIndex + 1))[0])) 
      } break;
      case 'END': { 
        dispatch(setActiveInfoItem(stateInputs.page_3.infoItemsArr[stateInputs.page_3.infoItemsArr.length -1])) 
      } break;
      default: break;
    }
  }

  
  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    window.onbeforeunload = () => {
      localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
    return () => {
      toast.dismiss()
    }
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
        localStorage.setItem("_binddata", JSON.stringify(stateInputs));
    };
  }, [stateInputs]);
  useEffect(() => {
    if (localStorage.getItem('MIID')) {
      const targetInfoItem = stateInputs.page_3.infoItemsArr.find((infoItem) => infoItem.infoItemId === localStorage.getItem('MIID'));
      if (targetInfoItem.infoItemId && targetInfoItem.infoItemIndex) dispatch(setActiveInfoItem(targetInfoItem));
      localStorage.removeItem('MIID')
    } else {
      dispatch(setActiveInfoItem(stateInputs.page_3.activeInfoItem ? stateInputs.page_3.activeInfoItem : stateInputs.page_3.infoItemsArr[0]))
    }
  }, []);
  
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_3").title} />
      <div style={{width: 'fit-content', position: 'fixed', bottom: '50px', left: '50%', transform: 'translateX(-50%'}}><Toaster position="bottom-center" reverseOrder={false} toastOptions={{duration: 5000, style: { minWidth: '350px'}}}/></div>
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_3} className={'vector_3'} alt={''}/>
            </div>
            <div className={"attachToBottom"}>
              <MainLogo />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={'main-content'}>
            <InfoModal show={modalShow} onHide={() => setModalShow(false)} title={'בעיה מורכבת מ ...'}>
                <Diagram />
              </InfoModal>
                <QuestFrame orderNum={4} frameType={"order"} additionalStyles={{margin: '0', minHeight: '30%'}}>
                  <div className={"font-weight-bold textBlue p-0 mb-1 md-text"}>
                    כתבי את כל המידע שידוע לך על הבעיה.
                    <br />
                    <p className={"text-muted font-weight-bold sm-text"}>
                      במסך זה יש להזין מידע רלוונטי להבנת הבעיה. המידע ישפיע על המשך העבודה במסכים הבאים. לכן נמליץ, לייצר פריט מידע נפרד לכל נתון. למשל,<br/>
                      במקום "5 פצועים בשנה מתוך 100" מומלץ להזין בנפרד "5 פצועים" ו"100 פועלים עובדים בכל שנה". 
                      ניתן להתרשם כיצד להזין <span className={'generalBlueLink textBlue font-weight-bold'} onClick={() => setModalShow(true)}>בתרשים הבא. </span>  <br/>
                      עבור כל פריט מידע סמני האם הוא עובדה אמפירית מגובה במקור או השערה.<br/>
                      בנוסף, סמני האם מדובר בסיבה לבעיה או בתיאור הנזק 
                       <span className={'xs-text'}> (ניתן גם לסמן אם אינך יודעת כרגע) </span>
                    </p>
                  </div>
                  </QuestFrame>
                  {stateInputs.page_3.activeInfoItem && stateInputs.page_3.infoItemsArr.length ? <div className={`${styles.pageContent}`}>
                      <QuestFrame
                        frameType={"info-item"}
                        additionalStyles={{margin: '0', padding: '6px 30px'}}
                        orderNum={stateInputs.page_3.activeInfoItem.infoItemIndex}>
                        <InfoItem action={handleSubmit} removeInfoItemAction={removeInfoItemAction} data={stateInputs.page_3.activeInfoItem} />
                      </QuestFrame>
                    <div className={`${styles.pageNavigateTool}`}>
                      <div className={'align-self-end'}>
                        <CircleButton action={addInfoItemAction} buttonColor={'#82BB2D'} textUnder={true} title={'הוספת פריט מידע'}  text={'הוספת פריט מידע'} iconComponent={<Plus size={50}/>} additionalClasses={'sm-text'} iconCircleAdditionalStyle={{ width: '38px', height: '38px', boxShadow: 'none', borderRadius: '10px', marginBottom: '8px' }}/>
                      </div>
                      <div className={'d-flex justify-content-between m-auto w-50'}>
                        {appConfiguration.infoItemsSearchTool.map((button) => 
                          <CircleButton 
                          key={button.title} 
                          action={browseInfoItems}
                          additionalArguments={button.browseOrder}
                          buttonColor={'#82bb2d'} 
                          text={button.textElem} 
                          textUnder={true} 
                          iconComponent={button.icon} 
                          title={button.title} 
                          additionalClasses={'sm-text'} 
                          iconCircleAdditionalStyle={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'transparent', color: '#82bb2d', boxShadow: 'none', padding: '3px'}}>
                          </CircleButton> 
                        )}
                      </div>
                    </div>
                  </div>: null}
            </div>
            <div className={'bottom-content attachToCenter'}>
              <p className={"text-muted sm-text"}>
                * ניתן לתעד את המקור בלחיצה על הלינק אבל לא חובה
              </p>
            </div>
          </div>
          <div className={'left-side-strip'}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={2} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./page-2")}/>
              <ChevronLeft className={"browseArrows"} onClick={() => history.push("./page-4")}/>
            </div>
          </div>
        </div>
      </div>
      <div className={'downer-line'}></div>
    </div>
  );
}