import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import { ChevronDown} from 'react-bootstrap-icons'
import styles from './chainItem.module.css'
import '../../../App.css'
import InfoItemCapsule from '../infoItemCapsule';
import ReactTooltip from 'react-tooltip';
import { appConfiguration } from '../../../store/configuration';

export default function ChainItem(props) {
    const { data, chainId, action, handleChange, itemIndex } = props
    const { radioGroup_1, infoItemId } = data;

    function dragStartHandler(e, item) {
        e.dataTransfer.setData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain', JSON.stringify(item))
        ReactTooltip.hide()
    }
    function dropHandler(e) {
        e.preventDefault()
        const item = e.dataTransfer.getData(appConfiguration.userAgent.IExplorerAgent ? 'text' : 'text/plain')
        action(JSON.parse(item), chainId, itemIndex)
    }
    function dragOverHandler(e) {
        e.preventDefault()
    }

    return (
        <>
            <div className={infoItemId ? styles.chainItemWrapper : styles.chainItemWrapperEmpt}>
                <div className={infoItemId ? styles.chainItemCapsule : styles.chainItemDropZone}>
                    { infoItemId ? 
                        <div className={'h-100'} onDragOver={dragOverHandler} onDrop={dropHandler}>
                            <InfoItemCapsule key={infoItemId} item={data} additionalClasses={'attachToCenter p-0 m-0 h-100'} dragStartHandler={(e) => dragStartHandler(e, data)}/>
                        </div> :
                        <div className={`${styles.emptDropZone}`} onDragOver={dragOverHandler} onDrop={dropHandler} >
                            <div className={"attachToCenter text-muted h-100 w-100 sm-text"}><span>{'גרור גורם לכאן'}</span></div>
                        </div>
                    }
                    <div className={`${styles.chainItemArrow} textBlue`}><ChevronDown size={'20px'} /></div>
                </div>
                <Form className={`${styles.chainItemForm} xs-text`}>
                    <label className={'customRadioBtn m-0'}>
                        <input type={'radio'} value={'עובדה אמפירית מגובה במקור'} disabled={typeof data === 'object' ? false : true} checked={radioGroup_1 === 'עובדה אמפירית מגובה במקור'} name={'radioGroup_1'} onChange={(e) => handleChange(e, itemIndex, 'setCIClassification', infoItemId)}/> 
                        <div className={'circle'}></div>
                        עובדה
                    </label>
                    <label className={'customRadioBtn m-0'}>
                        <input type={'radio'} value={'השערה'} disabled={typeof data === 'object' ? false : true} checked={radioGroup_1 === 'השערה'} name={'radioGroup_1'} onChange={(e) => handleChange(e, itemIndex, 'setCIClassification', infoItemId)}/>
                        <div className={'circle'}></div>
                        השערה
                    </label>
                </Form>
            </div>
        </>
    )
}

