import React, { useState, useEffect } from "react";
import QuerySection from "../../../../ui-components/query-section";
import CheckBoxButton from "../../../../ui-components/checkbox-button";
import { useHistory } from "react-router-dom";
import NavbarApp from "../../../../ui-components/nav-bar";
import vector_2 from "../../../../../assets/Vector_2.png";
import QuestFrame from "../../../../ui-components/quest-frame";
import QuestProgress from "../../../../ui-components/quest-progress";
import MainLogo from "../../../../ui-components/mainLogo";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import styles from "./page_2.module.css";
import { updatePage_2 } from "../../../../../store/inputs-reducer/inputs.actions";
import { getCookie, updateDocumentByPath } from "../../../../../firebase/helpers";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import { appConfiguration } from "../../../../../store/configuration";

export default function QuestPage2() {
  const stateInputs = useSelector(state => state.inputs)
  const dispatch = useDispatch()
  const initialLocalState = {
    input_A: stateInputs.page_2.input_A ? stateInputs.page_2.input_A : "",
    selectButtons: stateInputs.page_2.selectButtons
      ? stateInputs.page_2.selectButtons
      : [],
  };
  const [localState, setLocalState] = useState(initialLocalState);
  const history = useHistory();

  const isChecked = (value) =>
    localState.selectButtons.find((item) => item === value);

  function handleCheck({ target }) {
    const { name } = target;
    let checkboxArr = localState.selectButtons;
    isChecked(name)
      ? (checkboxArr = checkboxArr.filter((value) => value !== name))
      : checkboxArr.push(name);
    setLocalState({ ...localState, selectButtons: checkboxArr });
    dispatch(updatePage_2({ name: "selectButtons", value: checkboxArr }));
    updateDocumentByPath( getCookie("currentDocId"), checkboxArr, `page_2.selectButtons` );
  };

  function handleBlur({ target }) {
    const { value, name } = target;
    if (value === localState[name]) return;
    setLocalState({ ...localState, [name]: value });
    dispatch(updatePage_2({ name: name, value: value }));
    updateDocumentByPath( getCookie("currentDocId"), value, `page_2.${name}` );
  };


  useEffect(() => {
    if (!getCookie("currentDocId")) {
      history.push("/home");
      return;
    }
    window.onload = () => {
      localStorage.removeItem("_binddata");
    };
  }, []);
  useEffect(() => {
    window.onbeforeunload = () => {
        localStorage.setItem("_binddata", JSON.stringify(stateInputs));
      };
  }, [stateInputs]);
  return (
    <div className={"main-page-wrapper"}>
      <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "page_2").title} />
      <div className={'inner-wrapper'}>
        <div className={'inner-grid'}>
          <div className={'right-side-strip'}>
            <div className={"d-flex align-items-center flex-grow-1"}>
              <img src={vector_2} className={'vector_2'} alt={''}/>
            </div>
            <div className={"attachToBottom"}>
              <MainLogo />
            </div>
          </div>
          <div className={'main-content-wrapper'}>
            <div className={'main-content'}>
              <QuestFrame orderNum={3} frameType={"order"} additionalClasses={'h40'}  additionalStyles={{flexGrow: '1'}}>
                <QuerySection type={"textarea"} value={localState.input_A} name={"input_A"} action={handleBlur} question={"א. מהו האינטרס הציבורי עליו את אמונה בצורה ישירה ונפגע עקב הבעיה ? פרטי ככל שניתן ומומלץ לא להסתפק בהצהרות כלליות."} ph={'לדוגמא: פגיעה בשלומם ובטיחותם של הילדים.'}/>
              </QuestFrame>
              <QuestFrame frameType={"unorder"} additionalClasses={'h60'} additionalStyles={{minHeight: '60%'}}>
                <div className={"d-flex flex-column w-100 h-100"}>
                  <div >
                    <p className={"font-weight-bold textBlue p-0 m-0 md-text"}> ב. אילו אינטרסים ציבוריים נוספים נפגעים עקב הבעיה? </p>
                    <span className={"text-muted font-weight-bold sm-text"}>בחרי מהרשימה</span>
                  </div>
                      <div className={`btn-group-toggle ${styles.buttonsWrapper}`}>
                        {appConfiguration.buttonsInPage_2.map((item) => {
                          return (
                            <CheckBoxButton key={item} action={handleCheck} isChecked={isChecked(item)} name={item} />
                          );
                        })}
                    </div>
                </div>
              </QuestFrame>
            </div>
            <div className={'bottom-content'}></div>
          </div>
          <div className={'left-side-strip'}>
            <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
              <QuestProgress pageNumber={1} />
            </div>
            <div className={"navButtonsWrapper textGreen"}>
              <ChevronRight className={"browseArrows"} onClick={() => history.push("./page-1")}/>
              <ChevronLeft className={"browseArrows"} onClick={() => history.push("./page-3")}/>
            </div>
          </div>
        </div>
      </div>
      <div className={'downer-line'}></div>
    </div>
  );
}
