import ForgotPassword from '../pages/forgot-password/forgot_pass';
import Home from '../pages/home/home';
import IntroPage from '../pages/intro';
import Login from '../pages/login/login';
import QuestPage1 from '../pages/quest-pages/page-1';
import QuestPage2 from '../pages/quest-pages/page-2';
import QuestPage3 from '../pages/quest-pages/page-3';
import QuestPage4 from '../pages/quest-pages/page-4';
import QuestPage5 from '../pages/quest-pages/page-5';
import QuestPage6 from '../pages/quest-pages/page-6';
import SummaryPage from '../pages/quest-pages/summary-page';
import SignUp from '../pages/signup/signup';

const RoutesConfiguration = [
    {
        component: Home, path: '/home', name: 'home', exact: true
    } ,
    {
        component: Login, path: '/sign-in', name: 'login'
    } ,
    {
        component: ForgotPassword, path: '/forgot-password', name: 'forgot-password'
    } ,
    {
        component: SignUp, path: '/sign-up', name: 'signup'
    } ,
    {
        component: IntroPage, path: '/intro', name: 'intro'
    } ,
    {
        component: QuestPage1, path: '/page-1', name: 'page-1'
    } ,
    {
        component: QuestPage2, path: '/page-2', name: 'page-2'
    } ,
    {
        component: QuestPage3, path: '/page-3', name: 'page-3'
    } ,
    {
        component: QuestPage4, path: '/page-4', name: 'page-4'
    } ,
    {
        component: QuestPage5, path: '/page-5', name: 'page-5'
    } ,
    {
        component: QuestPage6, path: '/page-6', name: 'page-6'
    } ,
    {
        component: SummaryPage, path: '/summary-page', name: 'summary-page'
    } ,
]

export default RoutesConfiguration;