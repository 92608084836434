import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight, ExclamationTriangle, FileEarmarkArrowDown, HouseDoor, Printer } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../../App.css'
import { checkMainClassifications, findMissingInformationItems, getInfoItemsInPercentage, initialSummaryStateAction } from '../../../../../store/summary-reducer/summary.actions';
import { updateSummaryFields } from '../../../../../store/inputs-reducer/inputs.actions';
import MainLogo from '../../../../ui-components/mainLogo';
import NavbarApp from '../../../../ui-components/nav-bar';
import PillButton from '../../../../ui-components/pillButton';
import OverlayTrigger  from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import QuestProgress from '../../../../ui-components/quest-progress';
import SummaryItemsWrapper from '../../../../ui-components/summary-items-wrapper';
import styles from './summary.module.css';
import { Col } from 'react-bootstrap';
import { getCookie } from '../../../../../firebase/helpers';
import InfoItemCapsule from '../../../../ui-components/infoItemCapsule';
import { appConfiguration } from '../../../../../store/configuration';
import HiddenChain from '../../../../ui-components/hidden-chain';
import printJS from 'print-js'


export default function SummaryPage() {
    const stateInputs = useSelector((state) => state.inputs);
    const stateSummary = useSelector(state => state.summary);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch()
    const captureContainer = useRef()
    const hiddenChainsContainerRef = useRef()
    const buttonsContainerRef = useRef()
    const leftSideContainerRef = useRef()
    const history = useHistory();

    const listItemPopOver = (id, descriptText = '') => {
      return ( 
        <Popover id={id} className={styles.missinItemPopOver}>
          <Popover.Title className={'md-text bgBlue textWhite font-weight-bold'} title={descriptText}>
            {descriptText.length > 120 ? descriptText.substr(0,120) + '...' : descriptText.length ? descriptText: 'לא נרשם תיאור'}
          </Popover.Title>
          <Popover.Content>
            <p className={'textGray'}> לפריט מידע זה חסר מידע !</p>
            <p className={'textRed sm-text text-center p-0 m-0'}><ExclamationTriangle /> לחצי למעבר למידע החסר</p>
          </Popover.Content>
        </Popover>)
    } 
    function handleChange(e, isObj) {
      dispatch(updateSummaryFields({target: e.target, isObj: isObj}))
    }
    function handlePrint () {
      if (appConfiguration.userAgent.IExplorerAgent) return;
      printJS({
        printable: 'captureContainer',
        maxWidth: windowWidth,
        font_size: windowWidth > 1500 ? windowWidth > 1800 ? '15pt' : '14pt' : windowWidth > 1300 ? '13pt' : '11pt',
        type: 'html',
        css: ["https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap"], 
        style: `@page { size: landscape; margin: 0 5mm;} body { margin: 0; } #captureContainer { top: 0 !important; }`, 
        targetStyles: ['*']},
      'html')
    }

    function initialSummaryState() {
      dispatch(findMissingInformationItems({stateInputs}))
      dispatch(getInfoItemsInPercentage({stateInputs}))
      dispatch(checkMainClassifications({stateInputs}))
    }

    function whereToNavigate() {
        dispatch(initialSummaryStateAction())
        if (stateInputs.page_5.chains.length >= 2) {
          history.push("./page-5");
        } else {
          history.push("./page-6");
        }
      }
      function navigateToMissingItem(missingItem) {
        localStorage.setItem('MIID', `${missingItem.infoItemId}`)
        history.push(`page-3`)
      }
      
      useEffect(() => {
        if (!getCookie("currentDocId")) {
          history.push("/home");
          return;
        }
        initialSummaryState()
        window.onresize = () => {
          setWindowWidth(window.innerWidth)
        }
        window.onload = () => {
          localStorage.removeItem("_binddata");
        };
        return () => {
          window.onbeforeunload = null;
          window.onload = null;
          window.onresize = null;
        };
      }, []);
      useEffect(() => {
        window.onbeforeunload = () => {
          localStorage.setItem("_binddata", JSON.stringify(stateInputs));
        };
      }, [stateInputs]);
    return (
      <div className={"main-page-wrapper"}>
        <NavbarApp title={appConfiguration.questPagesConfig.appPages.orderedPages.find((page) => page.slug === "summary_page").title} />
        <div className={'inner-wrapper'}>
          <div className={`${styles.summaryInnerGrid} inner-grid`}>
            {windowWidth > 1200 && <div className={'right-side-strip justify-content-end'}>
              <div>
                <MainLogo height={"70px"} />
              </div>
            </div>}
                          
              <div className={'main-content-wrapper'}>
              <div className={`${styles.summaryMainContent} main-content p-0`} ref={captureContainer}>
                <div className={`${styles.contentGrid} captureContent textGray`}>
                    <div className={`${styles.gridItem} gridItem ${styles.gridItem_A} quest-frame`}>
                        <p className={'textBlue md-text'}>עוצמת הפגיעה כפי שתיארת אותה 
                        <span className={'d-block text-muted sm-text'}>הגעת למסך הסיכום, ניתן לצפות בשרשראות הסיבתיות שהזנת לאחר הדפסה/שמירה. </span>
                        </p>
                        <div className={'flex-grow-1 d-flex'}>
                          <SummaryItemsWrapper />
                        </div>
                    </div>
                    <div className={`${styles.gridItem} gridItem ${styles.gridItem_B} quest-frame sm-text`}>
                      <p className={'font-weight-bold mb-1 m-0 p-0'}>נתונים שלא ידעתי לקטלג:</p>
                      <Col className={'position-relative'}>
                        {stateSummary.missingInformationItems.length ? <div className={`${styles.missingItemsList} custom-scrollbar contentFade`} style={{listStyleType: 'hebrew'}}>
                          {stateSummary.missingInformationItems.map((infoItem, i) => {
                            return (
                              <OverlayTrigger 
                                key={infoItem.infoItemId + i}
                                trigger={["hover","focus"]} 
                                placement="top" 
                                overlay={listItemPopOver(infoItem.infoItemId + i, infoItem.descriptText, infoItem.popOverData)}>
                                  <div style={{width: '20%'}}>
                                    <InfoItemCapsule 
                                    key={infoItem.infoItemId} 
                                    item={infoItem} 
                                    toolTip={false} 
                                    itemDraggable={false}
                                    itemClickable={true}
                                    action={navigateToMissingItem}/>
                                  </div>
                              </OverlayTrigger>
                            )
                          })}
                        </div> : <p className={'xs-text text-success'}>כל המידע במסמך הושלם !</p>}
                      </Col>
                    </div>
                    <div className={`${styles.gridItem} gridItem ${styles.gridItem_D} quest-frame`}>
                        <p className={'m-0 p-0 font-weight-bold md-text'} style={{textAlgn: 'left'}}>שימי לב!</p>
                        {stateSummary.infoItemsInPercentage.hypotheses > 40 ?
                          <p className={'m-0 p-0 sm-text'}> {(stateSummary.infoItemsInPercentage.fact < 50 && stateSummary.infoItemsInPercentage.fact > 0) && 'רק כ -'} {stateSummary.infoItemsInPercentage.fact + '%'} מהשרשרת הנסיבתית שלך מבוססת על עובדות 
                            <span className={'textRed font-weight-bold'}> ({stateSummary.infoItemsInPercentage.hypotheses + '%'} מבוסס על השערות) </span> 
                          </p>
                            :
                          <p className={'m-0 p-0 sm-text text-success'}> {stateSummary.infoItemsInPercentage.fact + '%'} מהשרשרת הנסיבתית שלך מבוססת על עובדות </p>
                        }
                    </div>
                    <div className={`${styles.gridItem} gridItem ${styles.gridItem_E} quest-frame`}>
                        <p className={'m-0 p-0 font-weight-bold md-text'}>שימי לב!</p>
                        { !stateSummary.mainClassifications.thereIsFrequencyScopeItems || !stateSummary.mainClassifications.thereIsFrequencyAmountItems || !stateSummary.mainClassifications.thereIsSeverityItems ? 
                          <div className={'m-0 p-0 sm-text textRed'}> 
                            <span className={'pl-1 '}>לא הגדרת את עוצמת הפגיעה המלאה</span>
                            <span className={'text-muted'}>(חומרה X הסתברות)</span>
                          </div> :
                          <p className={'m-0 p-0 sm-text text-success'}> עוצמת הפגיעה הוגדרה במלואה ! </p>
                        
                        }
                    </div>
                    <div className={`${styles.gridItem} gridItem ${styles.gridItem_F} quest-frame`}>
                      <div className={'d-flex flex-column'}>
                        <p className={'m-0 p-0 font-weight-bold sm-text'}>האם לאחר השימוש בכלי ניתן להגדיר בצורה מבוססת את הבעיה? <br/> אם כן, מהי?</p>
                        <textarea className={'rulesTextarea custom-scrollbar sm-text text-muted textarea_p'} defaultValue={stateInputs.summaryFields.afterUsingTheTool} rows={2} name={`afterUsingTheTool`} style={{resize: 'none'}} onBlur={(e) => handleChange(e, false)}></textarea>
                      </div>
                      <div className={'d-flex flex-column flex-grow-1'}>
                        <p className={'m-0 p-0 font-weight-bold sm-text'}>הגדירי משימות ורשמי הערות להמשך טיפול :</p>
                        <div className={'d-flex flex-column pr-1 xs-text flex-grow-1 text-muted sm-text'}>
                            <div className={`${styles.textarea_F}`}>
                              <span>א.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p `} defaultValue={stateInputs.summaryFields.futureTasks.task_1} rows={1} name={`task_1`} style={{resize: 'none'}} onBlur={(e) => handleChange(e, true)}></textarea>
                            </div>
                            <div className={`${styles.textarea_F}`}>
                              <span>ב.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p`} defaultValue={stateInputs.summaryFields.futureTasks.task_2} rows={1} name={`task_2`} style={{resize: 'none'}} onBlur={(e) => handleChange(e, true)}></textarea>
                            </div>
                            <div className={`${styles.textarea_F}`}>
                              <span>ג.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p`} defaultValue={stateInputs.summaryFields.futureTasks.task_3} rows={1} name={`task_3`} style={{resize: 'none'}} onBlur={(e) => handleChange(e, true)}></textarea>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              
              <div className={'bottom-content py-3'}>
                {windowWidth <= 1200 && <div className={'align-self-end'}>
                    <MainLogo/>
                </div>}
                <div className={'flex-grow-1 d-flex align-items-center justify-content-center h-100'} ref={buttonsContainerRef}>

                    <PillButton 
                    iconComponent={<HouseDoor color={'white'} size={22}/>} 
                    action={() => {dispatch(initialSummaryStateAction()); history.push('./home')}}
                    buttonColor={'#82bb2d'}
                    text={'חזרה לעמוד הראשי'}
                    containerAdditionalStyle={{ padding: '1.2% 3%', height: '30px', marginRight: '15px', boxShadow: '1px 2px 6px #82bb2d'}}
                    additionalClasses={'md-text'}
                    />

                    <PillButton 
                    iconComponent={<FileEarmarkArrowDown color={'white'} size={22}/>}
                    tooltipData={appConfiguration.userAgent.IExplorerAgent ? "אופציה זו אינה נתמכת בדפדפן Internet Explorer !<br/> מומלץ להשתמש בדפדפן Chrome או Microsoft Edge לקבלת תמיכה מקסימלית." : ""}
                    action={handlePrint}
                    buttonColor={'#82bb2d'}
                    text={'שמירה כ - PDF'}
                    containerAdditionalStyle={{ padding: '1.2% 3%', height: '30px', marginRight: '15px', boxShadow: '1px 2px 6px #82bb2d'}}
                    additionalClasses={'md-text'}
                    />

                    <PillButton 
                    iconComponent={<Printer color={'white'} size={22}/>}
                    tooltipData={appConfiguration.userAgent.IExplorerAgent ? "אופציה זו אינה נתמכת בדפדפן Internet Explorer !<br/> מומלץ להשתמש בדפדפן Chrome או Microsoft Edge לקבלת תמיכה מקסימלית." : ""}
                    action={handlePrint}
                    buttonColor={'#82bb2d'}
                    text={'הדפסה'}
                    containerAdditionalStyle={{ padding: '1.2% 3%', height: '30px', marginRight: '15px', boxShadow: '1px 2px 6px #82bb2d'}}
                    additionalClasses={'md-text'}
                    />
                </div>
              </div> 
            </div>
            <div className={'left-side-strip'} ref={leftSideContainerRef}>
              <div className={`d-flex align-items-center justify-content-center flex-grow-1 ${appConfiguration.userAgent.IExplorerAgent && 'mb-5'}`}>
                <QuestProgress pageNumber={6} />
              </div>
              <div className={"navButtonsWrapper textGreen"}>
                <ChevronRight className={"browseArrows"} onClick={whereToNavigate}/>
                <ChevronLeft className={"browseArrows-inactive"}/>
              </div>
          </div>
          </div>
        </div>
        <div className={'downer-line'}></div>
        {PrintComponent({captureContainer, stateInputs, stateSummary, hiddenChainsContainerRef})}
      </div>
    )
}


function PrintComponent(props) {
  const { captureContainer, stateInputs, stateSummary, hiddenChainsContainerRef} = props;
  const [chains, setChain] = useState([])

  function setHiddenChains(pages) {
    let chainsArr = [];
    for (let i = 0; i < pages.length; i++) {
      chainsArr = [
        ...chainsArr, ...stateInputs[pages[i]].chains.filter((chain) => {
          const chainItemsAsObjects = chain.chainItems.filter(item => typeof item === 'object');
          chain.chainItems = chainItemsAsObjects; 
          if (chainItemsAsObjects.length) return chain;
        })
      ] 
    }
    setChain(chainsArr)
  }

  useEffect(() => {
    stateInputs.page_5.chains.length >= 2 ? setHiddenChains(['page_5']) : setHiddenChains(['page_5', 'page_6']) 
  }, [])
  return (
    <div className={`main-content p-0`} id={'captureContainer'} ref={captureContainer} style={{position: 'absolute', bottom: '-120%', left: '0.5%', maxWidth: '1520px'}}>
      <div className={'quest-frame_p p-2'}>
        <p className={'textGreen p-0 m-0 xxl-text'}>מה הבעיה :</p>
        <span className={'text-muted p-0 m-0'}>{stateInputs.page_1.input_A.length > 160 ? stateInputs.page_1.input_A.substr(0,160) + '...' : stateInputs.page_1.input_A.length ? stateInputs.page_1.input_A : 'אין תיאור לבעיה'}</span>
      </div>
      <div className={`${styles.contentGrid} textGray my-5`} style={{minHeight: '700px'}}>
          <div className={`${styles.gridItem} gridItem ${styles.gridItem_A} quest-frame_p`}>
              <p className={'textBlue md-text'}>עוצמת הפגיעה כפי שתיארת אותה 
              <span className={'d-block text-muted sm-text'}>הגעת למסך הסיכום, ניתן לצפות בשרשראות הסיבתיות שהזנת לאחר הדפסה/שמירה. </span>
              </p>
              <div className={'flex-grow-1 d-flex flex-column h-100'}>
                <SummaryItemsWrapper/>
              </div>
          </div>
          <div className={`${styles.gridItem} gridItem ${styles.gridItem_B} quest-frame_p sm-text`}>
            <p className={'font-weight-bold mb-1 m-0 p-0'}>נתונים שלא ידעתי לקטלג:</p>
            <Col className={'position-relative'}>
              {stateSummary.missingInformationItems.length ? <div className={`${styles.missingItemsList} custom-scrollbar contentFade`} style={{listStyleType: 'hebrew'}}>
                {stateSummary.missingInformationItems.map((infoItem, i) => {
                  return (
                        <div style={{width: '20%'}} key={infoItem.infoItemId}>
                          <InfoItemCapsule 
                          key={infoItem.infoItemId}
                          action={() => null}
                          item={infoItem} 
                          toolTip={false} 
                          itemDraggable={false}
                          itemClickable={true} />
                        </div>
                  )
                })}
              </div> : <p className={'xs-text text-success'}>כל המידע במסמך הושלם !</p>}
            </Col>
          </div>
          <div className={`${styles.gridItem} gridItem ${styles.gridItem_D} quest-frame_p`}>
              <p className={'m-0 p-0 font-weight-bold md-text'} style={{textAlgn: 'left'}}>שימי לב!</p>
              {stateSummary.infoItemsInPercentage.hypotheses > 40 ?
                <p className={'m-0 p-0 sm-text'}> {(stateSummary.infoItemsInPercentage.fact < 50 && stateSummary.infoItemsInPercentage.fact > 0) && 'רק כ -'} {stateSummary.infoItemsInPercentage.fact + '%'} מהשרשרת הנסיבתית שלך מבוססת על עובדות 
                  <span className={'textRed font-weight-bold xs-text'}> ({stateSummary.infoItemsInPercentage.hypotheses + '%'} מבוסס על השערות) </span> 
                </p>
                  :
                <p className={'m-0 p-0 sm-text text-success'}> {stateSummary.infoItemsInPercentage.fact + '%'} מהשרשרת הנסיבתית שלך מבוססת על עובדות </p>
              }
          </div>
          <div className={`${styles.gridItem} gridItem ${styles.gridItem_E} quest-frame_p`}>
              <p className={'m-0 p-0 font-weight-bold md-text'}>שימי לב!</p>
              { !stateSummary.mainClassifications.thereIsFrequencyScopeItems || !stateSummary.mainClassifications.thereIsFrequencyAmountItems || !stateSummary.mainClassifications.thereIsSeverityItems ? 
                <div className={'m-0 p-0 sm-text textRed'}> 
                  <span className={'pl-1 '}>לא הגדרת את עוצמת הפגיעה המלאה</span>
                  <span className={'text-muted'}>(חומרה X הסתברות)</span>
                </div> :
                <p className={'m-0 p-0 sm-text text-success'}> עוצמת הפגיעה הוגדרה במלואה ! </p>
              
              }
          </div>
          <div className={`${styles.gridItem} gridItem ${styles.gridItem_F} quest-frame_p`}>
            <div className={'d-flex flex-column'}>
              <p className={'m-0 p-0 font-weight-bold sm-text'}>האם לאחר השימוש בכלי ניתן להגדיר בצורה מבוססת את הבעיה? <br/> אם כן, מהי?</p>
              <textarea className={'rulesTextarea custom-scrollbar sm-text text-muted textarea_p'} defaultValue={stateInputs.summaryFields.afterUsingTheTool} rows={2} name={`afterUsingTheTool`} style={{resize: 'none'}}></textarea>
            </div>
            <div className={'d-flex flex-column flex-grow-1'}>
              <p className={'m-0 p-0 font-weight-bold sm-text'}>הגדירי משימות ורשמי הערות להמשך טיפול :</p>
              <div className={'d-flex flex-column pr-1 xs-text flex-grow-1 text-muted sm-text'}>
                  <div className={`${styles.textarea_F}`}>
                    <span>א.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p `} defaultValue={stateInputs.summaryFields.futureTasks.task_1} rows={1} name={`task_1`} style={{resize: 'none'}}></textarea>
                  </div>
                  <div className={`${styles.textarea_F}`}>
                    <span>ב.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p`} defaultValue={stateInputs.summaryFields.futureTasks.task_2} rows={1} name={`task_2`} style={{resize: 'none'}}></textarea>
                  </div>
                  <div className={`${styles.textarea_F}`}>
                    <span>ג.</span><textarea className={`rulesTextarea custom-scrollbar text-muted sm-text textarea_p`} defaultValue={stateInputs.summaryFields.futureTasks.task_3} rows={1} name={`task_3`} style={{resize: 'none'}}></textarea>
                  </div>
              </div>
            </div>
          </div>
      </div>
      {chains.length ? <div ref={hiddenChainsContainerRef} id={'hidden_chains_p'} className={'hidden-chains-container h-100'}>
        <div className={'textGreen xxl-text quest-frame_p p-2'}>סיכום שרשראות נסיבתיות</div>
        <div className={'d-flex flex-wrap align-items-start'}>
          {chains.map(chain => <HiddenChain key={chain.chainId} {...chain} />)}
        </div>
      </div> : null}
    </div>
  )
}