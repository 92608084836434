import { initialUserInputsState } from '../store/inputs-reducer/inputs.reducer';
import fire from 'firebase/app';
import 'firebase/auth'

const currentUserDocs = () => {
    if(!getCookie('userId')) return;
    return fire.firestore().collection('users-docs').doc(getCookie('userId')).collection('user-docs')
}
export function userLogOut() {
    removeCookie(["userId", "currentDocId"])
    fire.auth().signOut();
}

const getCurrentDateByFormat = () => {
    const date = new Date()
    let month = (date.getMonth()+1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear().toString().substr(-2);
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (month.length === 1) { month = "0" + month }
    if (day.length === 1) { day = "0" + day }
    if (hours.length === 1) { hours = "0" + hours }
    if (minutes.length === 1) { minutes = "0" + minutes }
  
    const dateFormat = `${hours}:${minutes} ${day}.${month}.${year}`  
    return dateFormat;
}

export async function getCurrentDoc(docId) {
    if(!docId) return
    const currentUserDocsRef = await currentUserDocs();
    return currentUserDocsRef.doc(docId).get().then((data) => {
        return data.data().doc
    })
    .catch((ex) => {
        console.error("Error fetching document: ", ex);
    });
}

export function updateDocumentByPath(docId, updatedValue, path) {
    if(!docId) return
    const currentUserDocsRef = currentUserDocs();
    const userDocRef = currentUserDocsRef.doc(docId)
    userDocRef.update({
        [`doc.${path}`] : updatedValue,
        updated: getCurrentDateByFormat()
    }).then(() => {
        console.log("Document successfully updated!");
    })
    .catch((ex) => {
        console.error("Error updating document: ", ex);
    });
}
export async function addNewDocument(duplicateState, docName) {
    const currentUserDocsRef = currentUserDocs();
    return await currentUserDocsRef.add({
        doc: duplicateState ? duplicateState : initialUserInputsState,
        docName: docName ? docName : '',
        created: getCurrentDateByFormat()
    }).then((docRef) => {
        return docRef.id;
    }).catch((ex) => {
        console.log(ex)
    })
}
export async function getUserDocs() {
    const currentUserDocsRef = currentUserDocs();
    return currentUserDocsRef.get().then((snapshot) => {
        const documents = snapshot.docs.map((doc) => {
            return {
            data: doc.data().doc,
            created: doc.data().created,
            updated: doc.data().updated,
            docId: doc.id,
            docName: doc.data().docName
            }
        })
        return documents;
    }).catch((ex) => {
        console.error(ex)
    })
}
export function deleteDocsValue(docId, path) {
    if(!docId) return
    const currentUserDocsRef = currentUserDocs();
    const userDocRef = currentUserDocsRef.doc(docId)
    userDocRef.update({
        [`doc.${path}`] : ''
    }).then(() => {
        console.log("Document data value was successfully deleted!");
    })
    .catch((ex) => {
        console.error("Error deleting document data: ", ex);
    });
}
export function deleteDoc(docId, callback) {
    if(!docId) return
    const currentUserDocsRef = currentUserDocs();
    currentUserDocsRef.doc(docId).delete().then(() => {
        console.log(`Document - ${docId} was successfully Deleted!`);
        callback()
    })
    .catch((ex) => {
        console.error(`Error deleting document - ${docId}: `, ex);
    });
}
export function updateDocFields(field, value, docId) {
    if(!docId) return
    const currentUserDocsRef = currentUserDocs();
    const userDocRef = currentUserDocsRef.doc(docId)
    userDocRef.update({
        [field] : value,
        updated: getCurrentDateByFormat()
    }).then(() => {
        console.log("Document successfully updated!");
    })
    .catch((ex) => {
        console.error("Error updating document: ", ex);
    });
}

export function setCookie(cookieName, cookieValue) {
    const d = new Date();
    const y = d.getFullYear() + 10;
    document.cookie = `${cookieName}=${cookieValue}; expires=Thu, 18 Dec ${y} 12:00:00 UTC; path=/`;   
}
export function getCookie(cookieName) {
    const name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
export function removeCookie(cookieArr) {
    for(let i = 0; i < cookieArr.length; i++) {
        document.cookie = `${cookieArr[i]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;   
    }
}