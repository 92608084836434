import React from 'react';
import logo from '../../../assets/logo.png'

export default function MainLogo() {
    return ( 
        <div>
            <img src={logo} className={'mainLogo'} alt={'main logo'}/>
        </div>

    )
}