import { ACTIONS } from '../actions.config';

export function updatePage_1(payload) {
    return {
      type: ACTIONS.UPDATE_PAGE_1,
      payload,
    };
}
export function updatePage_2(payload) {
    return {
      type: ACTIONS.UPDATE_PAGE_2,
      payload,
    };
}
export function updatePage_3(payload) {
    return {
      type: ACTIONS.UPDATE_PAGE_3,
      payload,
    };
}
export function updateChain(payload) {
    return {
      type: ACTIONS.UPDATE_CHAIN,
      payload,
    };
}
export function setDocumentState(payload) {
    return {
      type: ACTIONS.SET_DOCUMENT_STATE,
      payload,
    };
}
export function setRequestedItems(payload) {
    return {
      type: ACTIONS.SET_REQUESTED_ITEMS,
      payload,
    };
}
export function setDraggedItems(payload) {
    return {
      type: ACTIONS.SET_DRAGGED_ITEMS,
      payload,
    };
}
export function addInfoItem(payload) {
    return {
      type: ACTIONS.ADD_INFO_ITEM,
      payload,
    };
}
export function setActiveInfoItem(payload) {
    return {
      type: ACTIONS.SET_ACTIVE_INFO_ITEM,
      payload,
    };
}
export function removeInfoItem(payload) {
    return {
      type: ACTIONS.REMOVE_INFO_ITEM,
      payload,
    };
}
export function addChainItem(payload) {
    return {
      type: ACTIONS.ADD_CHAIN_ITEM,
      payload,
    };
}
export function removeChain(payload) {
    return {
      type: ACTIONS.REMOVE_CHAIN,
      payload,
    };
}
export function addChain(payload) {
    return {
      type: ACTIONS.ADD_CHAIN,
      payload,
    };
}
export function duplicateChain(payload) {
    return {
      type: ACTIONS.DUPLICATE_CHAIN,
      payload,
    };
}
export function unClassifyChain(payload) {
    return {
      type: ACTIONS.UN_CLASSIFY_CHAIN,
      payload,
    };
}
export function updateSummaryFields(payload) {
    return {
      type: ACTIONS.UPDATE_SUMMARY_FIELDS,
      payload,
    };
}